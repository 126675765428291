import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface SeriesData {
    name: string;
    data: number[];
}

interface ModelChartProps {
    modelName: string;
    series: SeriesData[];
    height: number;
}

function MyUsesChartCard({ modelName, series, height }: ModelChartProps) {
    const options: ApexOptions = {
        chart: {
            height: 330,
            type: 'line',
            zoom: {
                enabled: false,
            },
        },
        colors: ['#FBA834', '#1D24CA', '#51D1FE', '#00AEA4'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (value: number) => `$${value.toLocaleString()}`,
            },
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '12px',
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 500,
            labels: {
                colors: "#1B2559",
            },
            markers: {
                offsetX: -5,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 15,
                vertical: 15
            },
        },
        grid: {
            row: {
                colors: ['transparent'],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                show: true,
                rotate: -45, 
                style: {
                    fontSize: '12px',
                    fontWeight:400,
                    fontFamily: 'Plus Jakarta Sans',
                    colors:'#475467',
                },
            },
            tickPlacement: 'on',
        },
        yaxis: {
            title: {
                text: 'Value',
                offsetX: -12, 
                style: {
					color: "#1B2559",
                    fontSize: '14px',
                    fontWeight: 500,
                    fontFamily: 'Plus Jakarta Sans',
				},
            },
            labels: {
                offsetX: 0, 
                style: {
                    fontSize: '12px',
                    fontWeight:400,
                    fontFamily: 'Plus Jakarta Sans',
                    colors:'#475467',
                },
            },
        },
       
    };

    return (
        <div className="py-1 px-2">
            <div className="flex items-center justify-between">
                <h3 className="font-semibold text-unicon-lightBlue text-xl flex items-center justify-start">
                    Model Usage
                    <i className="icon icon-modelinfo ml-1"></i>
                </h3>
                <div className="flex items-center justify-between border border-solid border-unicon-grayBorder rounded-md">
                    <button className="h-[30px] w-[30px] flex items-center justify-center rounded-l bg-unicon-white text-base active-btn">$</button>
                    <button className="h-[30px] w-[30px] flex items-center justify-center rounded-r bg-unicon-white">
                        <i className="icon icon-currency"></i>
                    </button>
                </div>
            </div>
            <div className='pt-1 pl-4 pr-3 line-chart-dashboard' style={{ margin: '0 auto' }}>
                <ReactApexChart options={options} series={series} type="line" height={height} />
            </div>
        </div>
    );
}

export default MyUsesChartCard;
