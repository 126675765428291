export function getInitials(name: string | undefined) {
    if (name) {
        const initials = name
            .split(' ')
            .map((word) => word[0])
            .join('');
        return initials.toUpperCase();
    } else {
        return 'UU';
    }
}

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return `flex items-center justify-center w-[40px] h-[40px] rounded-full bg-[${color}] text-white text-xl font-semibold`;
  };

  export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }).format(date);
  };

  export const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toTimeString().split(' ')[0];
  };


  export const formattedDate = (datestring) => {
    const date = new Date(datestring);
    const formattedDate = date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });
    return formattedDate
  }
  
