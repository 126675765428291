import React, { useEffect } from 'react';
import Close from '../../../assets/modal_cross.svg';
import debounce from 'lodash.debounce';
import { UsersService } from '../../../libs/services/UsersService';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import LoadingSpinner from '../../../hooks/Loader';
import { getInitials } from '../Helper';
import { toast } from 'react-toastify';
import { ChatService } from '../../../libs/services/ChatService';
import { useChat } from '../../../libs/hooks';

export const AddUserPopup = ({ isOpen, onClose, group }) => {
    const chat = useChat();
    const chatService = new ChatService();
    const usersService = new UsersService();
    const { instance, inProgress } = useMsal();

    const [searchText, setsearchText] = React.useState('');
    const [loading, setloading] = React.useState(false);
    const [usersList, setusersList] = React.useState<any>([]);
    const [selectedUsers, setselectedUsers] = React.useState<any>([]);
    const [selectedUsersIdsArray, setselectedUsersIdsArray] = React.useState<any>([]);

    const topic = group?.title;

    const debouncedSearch = React.useCallback(
        debounce(async (keyword: string) => {
            setloading(true);
            if (keyword.trim() === '') {
                setusersList([]);
                setloading(false);
                return;
            }
            try {
                const result = await usersService.searchUsersAsync(
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                    keyword,
                );
                setloading(false);
                setusersList(result);
            } catch (error) {
                console.error('Error searching for users:', error);
            }
        }, 500),
        [instance, inProgress],
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setsearchText(newKeyword);
        debouncedSearch(newKeyword);
    };

    const toggleUser = (user) => {
        setusersList([]);
        setsearchText('');
        setselectedUsers((prevUsers) => {
            const userExists = prevUsers.some((existingUser) => existingUser?.id === user?.id);
            if (userExists) {
                return prevUsers.filter((existingUser) => existingUser?.id !== user?.id);
            } else {
                return [...prevUsers, user];
            }
        });
    };

    const cancel = () => {
        setselectedUsers([]);
        onClose();
    };

    const chatId = group?.id;

    useEffect(() => {
        const participantsIds: any = [];
        selectedUsers.map((user) => {
            participantsIds.push(`${user?.id}.412259ed-cfdd-49be-8186-814dfe58dc54`);
        });
        setselectedUsersIdsArray(participantsIds);
    }, [selectedUsers]);

    const body = {
        chatid: chatId,
        participantsIds: selectedUsersIdsArray,
        isgroupowner: true,
        actiontype: 'add',
    };

    const submit = async () => {
        toast.success('Updating Participants');
        cancel();
        try {
            await chatService
                .updateGroupParticipants(body, await AuthHelper.getSKaaSAccessToken(instance, inProgress))
                .then((response) => {
                    console.log(response.status)
                    toast.success('Participants Updated');
                });
        } catch (e: any) {
            console.log(e)
            const errorMessage = `Unable to Update Participants`;
            toast.error(errorMessage);
        } finally {
            chat.loadChats();
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50"
            style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                outline: '0',
                backgroundColor: 'rgba(38, 33, 58, 0.52)',
                backdropFilter: 'blur(2px)',
            }}
        >
            <div
                className="bg-white rounded-xl w-[516px]  z-10  relative"
                style={{ boxShadow: '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814' }}
            >
                <div className="flex justify-between py-4 px-[30px] border-b border-solid border-unicon-modalBorder w-full sticky top-0 z-20">
                    <h2 className='font-semibold text-[18px] text-unicon-lightBlue'>Add User(s)</h2>
                    <img src={Close} alt="" onClick={cancel} className="cursor-pointer" />
                </div>
                <div className=' w-full  px-[30px] mt-3 mb-5 '>
                <div className='flex items-center justify-start'>
                <span className='font-medium text-base text-unicon-lightBlue capitalize'>topic name :</span> 
                <span className='font-medium text-Hsm text-unicon-lightBlue capitalize pl-2'>{topic} </span>
                </div>
                <div className="mt-5 font-medium text-base text-unicon-lightBlue pb-1">Add User</div>
                <div className=' border border-solid border-unicon-modalBorder h-11 rounded-lg pr-5 pl-4 inline-flex items-center justify-start w-full' style={{boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03)'}}>
                    <i className='icon icon-add-user-serach'></i>
                <input
                    className="small-placeholder pl-3 text-base font-medium text-unicon-lightBlue focus-visible:outline-none"
                    type="text"
                    value={searchText}
                    onChange={handleInputChange}
                    placeholder='Search users...'
                />
                </div>
                <div className='min-h-72 max-h-80 w-full border border-solid border-[#F2F4F7] rounded-lg bg-white overflow-auto overflow-x-hidden scroll-div-chat' 
                style={{boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)'}}>
                {loading ? (
                    <LoadingSpinner width="24px" height="24px" />
                ) : (
                    usersList?.map((user, index) => (
                        <li
                            key={index}
                            className="userlist-hover flex items-center justify-between h-15 px-5 py-[10px] w-full cursor-pointer hover:bg-unicon-chatLiHover"
                            onClick={() => {
                                toggleUser(user);
                            }}
                        >
                            <div className="flex items-center justify-start">
                                <div className="flex items-center justify-center w-[36px] h-[36px] rounded-full bg-unicon-gray text-white text-base font-semibold">
                                    {getInitials(user.userName)}
                                </div>
                                <div
                                    className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px]"
                                    title={user.userName}
                                >
                                    {user.userName}
                                    <br />
                                    <span className="text-unicon-gray text-sm leading-none" title={`${user.emailid}`}>
                                        {user.emailid}
                                    </span>
                                </div>
                            </div>
                            <button className="w-6 h-6 flex items-center justify-center">
                                <i
                                    className={`icon ${selectedUsers.some((existingUser) => existingUser?.id === user?.id) ? 'icon-tick' : group?.users?.some((existingUser) => existingUser?.id === `${user?.id}.412259ed-cfdd-49be-8186-814dfe58dc54`) ? 'icon-tick' : 'icon-plus-filter invisible'}`}
                                ></i>
                            </button>
                        </li>
                    ))
                )}
                </div>
                <ul className="mt-6 scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[150px] w-full flex-wrap ">
                {selectedUsers.map((user) => {
                    return (
                        <li
                            key={user?.id}
                            className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] truncate py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                        >
                            <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px] truncate">
                                {user.userName}
                            </span>
                            <i
                                className="icon icon-close-pills cursor-pointer"
                                onClick={() => {
                                    toggleUser(user);
                                }}
                            ></i>
                        </li>
                       
                    );
                })}
                </ul>
                </div>
                <div className="flex items-center gap-6  w-full justify-end bg-white sticky bottom-0 h-20  rounded-b-xl px-[30px] border-t border-solid border-unicon-modalBorder">
                    <button className='btn secondary-btn' onClick={cancel}>Cancel</button>
                    <button className="btn primary-btn" onClick={submit}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default AddUserPopup;
