import { BaseService } from "./BaseService";

export class UsersService extends BaseService {
    public getUsersAsync = async (accessToken:string): Promise<any[]> => { 
        const result = await this.getResponseAsync<any[]>(
            {
                commandPath: 'users',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public searchUsersAsync = async (accessToken:string,keyword:string): Promise<any[]> => { 
        const result = await this.getResponseAsync<any[]>(
            {
                commandPath: `/users/search?name=${keyword}`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public getUsersLogAsync = async (accessToken:string,userId:string): Promise<any[]> => { 
        const result = await this.getResponseAsync<any[]>(
            {
                commandPath: `/users/logs?userid=${userId}`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public getUserRoles = async (accessToken:string): Promise<any> => { 
        const result = await this.getResponseAsync<any>(
            {
                commandPath: `user/profile`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public getUserProfile = async (accessToken:string): Promise<any> => { 
        const result = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value',{
            headers: {
                "Content-type": 'image/jpeg',
                "Authorization":`Bearer ${accessToken}`
              },
        })

        return result;
    };

    public addUserToPoolAsync = async (payload:any,accessToken): Promise<any> => {
        const body:any = payload
        const result = await this.getResponseAsync<any>(
            {
                commandPath: `users/addusertopool`,
                method: 'POST',
                body,
            },
            accessToken,
        );
        return result;
    };
}
