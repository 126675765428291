import { AuthenticatedTemplate } from '@azure/msal-react';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { BackendProbe, Loading } from '../components/views';
import { AppState } from '../App';

export const PrivateRoute = ({ children, role,setAppState,appState }) => {
    const navigate = useNavigate();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const email = activeUserInfo?.email;
    const entitlements = activeUserInfo?.entitlements;
    const currentUserRoles = activeUserInfo?.roles?.map((role) => role.roleName) || [];

    const onBackendFound = useCallback(() => {
        setAppState(AppState.SettingUserInfo);
    }, [setAppState]);
    
    useEffect(() => {
        if (email) {
            if (!currentUserRoles.includes(role)) {
                navigate('/access-denied');
            } else if (!entitlements?.modelId) {
                navigate('/no-entitlements');
            }
        }
    }, [email, currentUserRoles, entitlements, navigate]);

    if (appState === AppState.ProbeForBackend) {
        return <BackendProbe onBackendFound={onBackendFound} />;
    }

    if (appState === AppState.SettingUserInfo) {
        <Loading text={'Hang tight while we fetch your information...'} />;
    }

    if (currentUserRoles.includes(role) && entitlements?.modelId) {
        return (
            <AuthenticatedTemplate>
                <ToastContainer />
                {children}
            </AuthenticatedTemplate>
        );
    }
};
