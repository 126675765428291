// import profile from '../../../assets/Profile.svg';
// import edit from '../../../assets/Edit.svg';
// import zapIcon from '../../../assets/zap-icon.svg';
// import { ChatHistoryItem } from './ChatHistoryItem';

import { IChatMessage } from '../../../libs/models/ChatMessage';
import { ChatHistoryItem } from './ChatHistoryItem';

interface ChatHistoryProps {
    messages: IChatMessage[];
    scrollViewTargetRef:any
}

export const ChatHistory: React.FC<ChatHistoryProps> = ({ messages,scrollViewTargetRef }) => {
    return (
        <div className="flex grow mx-2 mb-2 relative" >
            <div className="scroll-div-chat  overflow-y-scroll flex flex-col items-start h-[calc(100vh-218px)]" ref={scrollViewTargetRef}>
                {messages?.map((message: IChatMessage, index: number) => (
                    <ChatHistoryItem key={message.timestamp} message={message} messageIndex={index} />
                ))}
                
            </div>
        </div>
    );
};
