import microsoftIcon from '../../assets/microsoft.svg';
import downArrow from '../../assets/down_arrow.svg';
import notification from '../../assets/notification.svg';
import theme from '../../assets/theme.svg';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { formatDate, formatTime, getInitials } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setSelectedModel } from '../../redux/features/models/modelsSlice';
import SettingModal from '../utils/settingModal';
import ProgressBarModal from '../utils/progressBarModal';
import chatimage1 from '../../assets/setting-modal/image-1.svg';
import chatimage2 from '../../assets/setting-modal/microsoft.svg';
import Archivedtable from '../ArchiveTable';
import { useMsal } from '@azure/msal-react';
import { UsersService } from '../../libs/services/UsersService';
import { AuthHelper } from '../../libs/auth/AuthHelper';

const ChatHeader: any = () => {
    const userService = new UsersService();
    const { instance, inProgress } = useMsal();
    const location = useLocation();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { selectedModel, modelsList } = useAppSelector((state: RootState) => state.models);
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState('MyProfile');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showMenu, setshowMenu] = useState(false);
    const [userLogs, setUserLogs] = useState<any>();
    const userId = activeUserInfo?.id.split('.')[0] ?? '';
    const currentUserRoles = activeUserInfo?.roles?.map((role) => role.roleName) || [];
    const { selectedId,conversations} = useAppSelector((state: RootState) => state.conversations);
    const selectedConversationTitle = conversations[selectedId]?.title

    const transformString = (str: string) => {
        return str.replace(/\//g, '').replace(/-/g, ' ');
    };
    const handleItemClick = (itemName: string) => {
        setSelectedItem(itemName);
    };
    const [isDropdownChatModel, setIsDropdownChatModel] = useState(false);

    const toggleDropdownChatModel = () => {
        setIsDropdownChatModel(!isDropdownChatModel);
    };

    const dispatch = useAppDispatch();

    const handleOptionSelect = (option: any): void => {
        dispatch(setSelectedModel(option));
        setIsDropdownChatModel(false);
    };
    const selectedModelName = selectedModel?.modelName
        ? `${selectedModel.modelName} (${selectedModel.poolName})`
        : 'Loading...';

    const getUserLogs = async () => {
        userService
            .getUsersLogAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress), userId)
            .then((response) => {
                setUserLogs(response);
            });
    };

    useEffect(() => {
        getUserLogs();
    }, []);

    const groupedActions = userLogs?.reduce((groups, action) => {
        const date = action.actionDate.split('T')[0];
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(action);
        return groups;
    }, {});

    let sortedDates;

    if (groupedActions) {
        sortedDates = Object.keys(groupedActions).sort((a, b) => b.localeCompare(a));
    }

    const popupRef = useRef<any>(null);

    const isAdmin = ["OVERSIGHT_MANAGER", "MODEL_MANAGER", "COST_MANAGER", "GROUP_MANAGER"]
    .some(role => currentUserRoles.includes(role));    const headerSettingMenu = ['Profile', 'My Models', 'My Usage', 'Chat History'];

    const onClose = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const getDynamicHeading = () => {
        if (location.pathname === '/') {
            return (
                <div className="relative">
                    <div
                        className="h-10 px-3 rounded-[8px] flex items-center justify-between  border border-unicon-modalBorder cursor-pointer w-[270px] bg-white mr-1"
                        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                        onClick={toggleDropdownChatModel}
                    >
                        <span className="inline-flex items-center justify-start">
                            <img src={microsoftIcon} alt="Microsoft icon" width={22} height={22} />
                            <h3 className="text-base leading-5 font-medium text-unicon-lightBlue pl-2 text-ellipsis overflow-hidden whitespace-nowrap max-w-[195px]">
                                {selectedModelName}
                            </h3>
                        </span>
                        <span className="w-[30px] h-[30px] flex items-center justify-end">
                            <img src={downArrow} alt="Dropdown arrow" width={28} height={28} />
                        </span>
                    </div>

                    {isDropdownChatModel && (
                        <div className=" bg-white rounded-[8px] shadow-lg absolute top-[40px] left-0 min-w-[270px] max-w-[300px] z-10 w-full">
                            <ul className="py-2 w-full">
                                {modelsList.map((model: any) => {
                                    return (
                                        <li
                                            onClick={() => {
                                                handleOptionSelect(model);
                                            }}
                                            key={model.modelId}
                                            className="px-4 py-2 hover:bg-unicon-chatLiHover cursor-pointer w-full inline-flex items-center justify-start"
                                        >
                                            <img src={microsoftIcon} alt="Microsoft icon" width={22} height={22} />
                                            <span className="text-base leading-5 font-medium text-unicon-lightBlue pl-2">
                                                {`${model.modelName} (${model.poolName})`}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div className="h-[44px] py-2 px-4 rounded-[8px] flex gap-4 bg-white">
                <h3 className="text-xl font-semibold  text-unicon-lightBlue capitalize">
                    {transformString(location.pathname)}
                </h3>
            </div>
        );
    };

    return (
        <div>
        <div className="px-4 py-5 flex justify-between  items-center w-full bg-white border-b border-solid border-unicon-grayBorder h-[68px] relative" 
        >
            {getDynamicHeading()}
            <div className="h-[44px] w-[calc(100%-275px)]  pl-6  rounded-[30px] flex items-center justify-end gap-3 ">
            <div className='w-auto h-10 rounded-lg border-[0.6px] border-solid border-unicon-blue px-[14px] py-[8px] font-medium text-base text-unicon-lightBlue flex items-center justify-center gap-3'>$ 32.50
                <i className='icon icon-info-blue'></i>
            </div>
                {/* <img src={notification} alt="microsoft icon" width={24} height={24} />
                <img src={theme} alt="microsoft icon" width={24} height={24} /> */}
                <div className="relative pl-2">
                    <div
                        className="flex items-center justify-center w-[38px] h-[38px]  rounded-full bg-unicon-gray text-white text-xl font-semibold cursor-pointer"
                        onClick={() => {
                            setshowMenu((showMenu) => !showMenu);
                        }}
                    >
                        {activeUserInfo?.photo ? (
                            <img className="w-full h-full rounded-full" src={activeUserInfo?.photo} alt="rightarrow" />
                        ) : (
                            getInitials(activeUserInfo?.username)
                        )}
                    </div>
                    {showMenu && (
                        <div
                            className="absolute right-0 rounded-[14px]  bg-white px-5 py-3 border w-[311px] z-50 hello"
                            style={{ boxShadow: '14px 17px 40px 4px #7090B02E' }}
                        >
                            <div className="flex items-center justify-start w-full">
                                <div
                                    className="flex items-center justify-center w-[42px] h-[42px]  rounded-full bg-unicon-gray text-white text-xl font-semibold cursor-pointer"
                                    onClick={() => {
                                        setshowMenu((showMenu) => !showMenu);
                                    }}
                                >
                                    {activeUserInfo?.photo ? (
                                        <img
                                            className="w-full h-full rounded-full"
                                            src={activeUserInfo?.photo}
                                            alt="rightarrow"
                                        />
                                    ) : (
                                        getInitials(activeUserInfo?.username)
                                    )}
                                </div>
                                <h4 className="w-[calc(100%-65px)] font-semibold text-xl text-unicon-lightBlue pl-3">
                                    {activeUserInfo && activeUserInfo.username} <br />
                                    <span className="font-medium text-base text-unicon-gray">
                                        {isAdmin ? 'Admin' : activeUserInfo && activeUserInfo.email}
                                    </span>
                                </h4>
                            </div>
                            <div className="border-t border-solid border-unicon-modalBorder mt-3">
                                <span className="block px-4 pt-4  text-sm text-unicon-gray font-medium">Settings</span>
                                {headerSettingMenu.map((item: string, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className="text-base font-medium text-unicon-lightBlue leading-4 capitalize cursor-pointer pl-4 h-[30px] w-full rounded inline-flex items-center justify-start hover:bg-unicon-chatLiHover"
                                            onClick={() => {
                                                if (item !== 'Settings') {
                                                    navigate(`/${item.toLowerCase().replace(/\s+/g, '-')}`);
                                                }
                                                setshowMenu(false);
                                                setIsModalOpen(true);
                                            }}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>

                            {isAdmin && (
                                <div className="border-t border-solid border-unicon-modalBorder mt-3">
                                    <span className="block px-4 pt-4  text-sm text-unicon-gray font-medium">
                                        Administration
                                    </span>
                                    <div
                                        className="text-base font-semibold text-unicon-lightBlue leading-4 capitalize cursor-pointer pl-4 h-[30px] w-full rounded inline-flex items-center justify-start hover:bg-unicon-chatLiHover"
                                        onClick={() => {
                                            if(currentUserRoles.includes("COST_MANAGER")){
                                                navigate(`/dashboard`);
                                            }else if(currentUserRoles.includes("MODEL_MANAGER")){
                                                navigate(`/models`);
                                            }else if(currentUserRoles.includes("GROUP_MANAGER")){
                                                navigate(`/pool-management`);
                                            }
                                           
                                        }}
                                    >
                                        Admin Panel
                                    </div>
                                </div>
                            )}

                            <div className="border-t border-solid border-unicon-modalBorder mt-3">
                                <span className="block px-4 pt-4  text-sm text-unicon-gray font-medium">
                                    Policy and account terms
                                </span>
                                <div className="text-base font-medium text-unicon-lightBlue leading-4 capitalize cursor-pointer pl-4 h-[30px] w-full rounded inline-flex items-center justify-start hover:bg-unicon-chatLiHover">
                                    Privacy Policy
                                </div>
                                <div className="text-base font-medium text-unicon-lightBlue leading-4 capitalize cursor-pointer pl-4 h-[30px] w-full rounded inline-flex items-center justify-start hover:bg-unicon-chatLiHover">
                                    Terms & Conditions
                                </div>
                                <button
                                    className="btn primary-btn rounded-[10px] flex items-center justify-center h-10 w-full mt-6  mb-4"
                                    onClick={() => instance.logoutRedirect()}
                                >
                                    <i className="icon icon-logout"></i>
                                    <span className="text-base  font-semibold">Sign out</span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* <div ref={popupRef}>
                        <SettingModal
                            isOpen={isModalOpen}
                            onClose={() => {
                                setIsModalOpen(false);
                            }}
                            title="Settings"
                        >
                            <div className="modalbody w-full relative h-[calc(100vh-14.3rem)]">
                                <div className="modal-content-wrap flex justify-start  h-[calc(100vh-16.3rem)]">
                                    <div className="modal-left-pannel w-[226px]  border-r border-solid border-unicon-grayBorder h-[calc(100vh-14.4rem)]">
                                        <ul className="mt-1">
                                            <li
                                                className={`h-12 w-full px-[28px] flex items-center justify-start bg-unicon-white gap-2 cursor-pointer ${
                                                    selectedItem === 'MyProfile' ? 'active-list' : ''
                                                }`}
                                                onClick={() => {
                                                    handleItemClick('MyProfile');
                                                }}
                                            >
                                                <i className="icon icon-profile"></i>
                                                <span className="text-unicon-lightBlue text-base font-medium capitalize">
                                                    My Profile & Usage
                                                </span>
                                            </li>
                                            <li
                                                className={`h-12 w-full px-[28px] flex items-center justify-start bg-unicon-white gap-2 cursor-pointer ${
                                                    selectedItem === 'ArchiveChat' ? 'active-list' : ''
                                                }`}
                                                onClick={() => {
                                                    handleItemClick('ArchiveChat');
                                                }}
                                            >
                                                <i className="icon icon-archive"></i>
                                                <span className="text-unicon-lightBlue text-base font-medium capitalize">
                                                    Archived Chats
                                                </span>
                                            </li>
                                            <li
                                                className={`h-12 w-full px-[28px] flex items-center justify-start bg-unicon-white gap-2 cursor-pointer ${
                                                    selectedItem === 'MyActivity' ? 'active-list' : ''
                                                }`}
                                                onClick={() => {
                                                    handleItemClick('MyActivity');
                                                }}
                                            >
                                                <i className="icon icon-activity"></i>
                                                <span className="text-unicon-lightBlue text-base font-medium capitalize">
                                                    My Activity
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="modal-right-pannel w-[calc(100%-226px)] h-[calc(100vh-16.3rem)] overflow-auto  px-[30px]">
                                        {selectedItem === 'MyProfile' && (
                                            <div className="my-profile-main w-full pt-[25px] pb-[18px]">
                                                <div className="w-full border border-unicon-modalBorder border-solid rounded">
                                                    <div className="flex items-center justify-start border-b border-unicon-modalBorder  p-[10px] relative rounded-t">
                                                        <div
                                                            className="flex items-center justify-center w-[42px] h-[42px]  rounded-full bg-unicon-gray text-white text-xl font-semibold cursor-pointer"
                                                            onClick={() => {
                                                                setshowMenu((showMenu) => !showMenu);
                                                            }}
                                                        >
                                                            {getInitials(activeUserInfo?.username)}
                                                        </div>
                                                        <h3 className="text-xl font-semibold  text-unicon-lightBlue leading-6 pl-[12px]">
                                                            {activeUserInfo?.username}
                                                            <br />
                                                            <span className="text-base leading-4 text-unicon-gray font-medium">
                                                                Admin
                                                            </span>
                                                        </h3>
                                                        <button className="edit-button absolute top-2 right-2 w-7 h-7">
                                                            <i className="icon icon-rename modal-edit-icon"></i>
                                                        </button>
                                                    </div>
                                                    <form className="profile-form px-[30px] py-[25px]">
                                                        <div className="form-group-row  flex gap-4 mb-[14px]">
                                                            <div className="form-group-col">
                                                                <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                                                    First Name
                                                                </label>
                                                                <input
                                                                    className="min-w-[270px]  font-normal text-base text-unicon-lightBlue border border-unicon-inputBorder h-9 rounded flex items-center justify-start px-[14px] bg-unicon-dashboardlistHover focus-visible:outline-none"
                                                                    type="text"
                                                                    name="first-name"
                                                                    placeholder="Enter First Name"
                                                                    value={activeUserInfo?.username.split(' ')[0]}
                                                                ></input>
                                                            </div>
                                                            <div className="form-group-col">
                                                                <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                                                    Last Name
                                                                </label>
                                                                <input
                                                                    className="min-w-[270px]  font-normal text-base text-unicon-lightBlue border border-unicon-inputBorder h-9 rounded flex items-center justify-start px-[14px] bg-unicon-dashboardlistHover focus-visible:outline-none"
                                                                    type="text"
                                                                    name="last-name"
                                                                    placeholder="Enter last Name"
                                                                    value={activeUserInfo?.username.split(' ')[1]}
                                                                ></input>
                                                            </div>
                                                        </div>
                                                        <div className="form-group-row  flex gap-4 mb-[14px]">
                                                            <div className="form-group-col">
                                                                <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                                                    Email ID
                                                                </label>
                                                                <div className="inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px] bg-unicon-dashboardlistHover">
                                                                    <i className="icon email-icon"></i>{' '}
                                                                    <input
                                                                        className=" min-w-[224px]  font-normal text-base text-unicon-gray   flex items-center justify-start pl-[8px]  bg-unicon-dashboardlistHover  focus-visible:outline-none"
                                                                        type="email"
                                                                        disabled
                                                                        name="email"
                                                                        placeholder="Enter Email ID"
                                                                        value={activeUserInfo?.email}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                            <div className="form-group-col">
                                                                <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                                                    Contact No.
                                                                </label>
                                                                <div className="inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px] bg-unicon-dashboardlistHover">
                                                                    <i className="icon contact-icon"></i>{' '}
                                                                    <input
                                                                        className=" min-w-[224px]  font-normal text-base text-unicon-lightBlue   flex items-center justify-start pl-[8px]  bg-unicon-dashboardlistHover focus-visible:outline-none "
                                                                        type="number"
                                                                        disabled
                                                                        name="contact"
                                                                        placeholder="Not Available"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group-row  flex gap-4 ">
                                                            <div className="form-group-col">
                                                                <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                                                    Location
                                                                </label>
                                                                <div className="inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px] bg-unicon-dashboardlistHover">
                                                                    <i className="icon location-icon"></i>{' '}
                                                                    <input
                                                                        className=" min-w-[224px]  font-normal text-base text-unicon-lightBlue   flex items-center justify-start pl-[8px]  bg-unicon-dashboardlistHover focus-visible:outline-none "
                                                                        type="number"
                                                                        disabled
                                                                        name="contact"
                                                                        placeholder="Not Available"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="mt-5">
                                                    <h3 className="text-lg text-unicon-lightBlue font-medium">
                                                        Model Usage
                                                    </h3>
                                                    <div className="flex flex-1 gap-4 mt-[6px]">
                                                        <div className="card-wrap">
                                                            <div className="card-header inline-flex items-center justify-start">
                                                                <span className="w-[42px] h-[42px] flex items-center justify-start">
                                                                    <img
                                                                        src={chatimage1}
                                                                        alt="microsoft icon"
                                                                        width={40}
                                                                        height={40}
                                                                    />
                                                                </span>
                                                                <h4 className="pl-3 flex  flex-col">
                                                                    <span className="text-base text-unicon-lightBlue font-medium mb-[3px]">
                                                                        gpt-4o
                                                                    </span>
                                                                    <span className="text-unicon-gray text-sm font-normal ">
                                                                        Chat completion
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                            <div className="w-full pt-[10px]">
                                                                <ProgressBarModal value={56} max={100} />
                                                            </div>
                                                        </div>
                                                        <div className="card-wrap">
                                                            <div className="card-header inline-flex items-center justify-start">
                                                                <span className="w-[42px] h-[42px] flex items-center justify-start">
                                                                    <img
                                                                        src={chatimage2}
                                                                        alt="microsoft icon"
                                                                        width={40}
                                                                        height={40}
                                                                    />
                                                                </span>
                                                                <h4 className="pl-3 flex  flex-col">
                                                                    <span className="text-base text-unicon-lightBlue font-medium mb-[3px]">
                                                                        gpt-4-32k
                                                                    </span>
                                                                    <span className="text-unicon-gray text-sm font-normal ">
                                                                        Chat completion
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                            <div className="w-full pt-[10px]">
                                                                <ProgressBarModal value={78} max={100} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selectedItem === 'ArchiveChat' && (
                                            <div className="archive-Chats pt-[16px] pb-[18px]">
                                                <h4 className=" flex items-end justify-between pb-[8px]">
                                                    <span className="font-medium text-lg text-unicon-lightBlue">
                                                        Archived Chats
                                                    </span>
                                                    <div className="inputwrap h-10 flex justify-start relative items-end">
                                                        <i className="icon icon-input-search absolute right-0 cursor-pointer"></i>
                                                        <input
                                                            className="min-w-[200px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[6px] pr-[26px]  text-sm text-unicon-lightBlue font-medium"
                                                            type="text"
                                                            placeholder="Search chats.."
                                                        />
                                                    </div>
                                                </h4>
                                                <div className="w-full">
                                                    <Archivedtable />
                                                </div>
                                            </div>
                                        )}

                                        {selectedItem === 'MyActivity' && (
                                            <div className="my-activity pt-[16px] pb-[18px]">
                                                <h4 className="font-medium text-lg text-unicon-lightBlue pb-[8px]">
                                                    My Activity
                                                </h4>
                                                <div className="w-full border border-unicon-grayBorder rounded  pt-[25px]">
                                                    <div className="h-[calc(100vh-340px)] overflow-hidden overflow-y-auto scroll-div">
                                                        {sortedDates.map((date) => {
                                                            return (
                                                                <div key={date}>
                                                                    <h4 className="flex items-center justify-start pl-[25px]">
                                                                        <span className="rounded-full w-[6px] h-[6px] bg-unicon-dotColor"></span>
                                                                        <span className="text-unicon-lightBlue font-semibold text-base leading-4 pl-2">
                                                                            {formatDate(date)}
                                                                        </span>
                                                                    </h4>
                                                                    <ul className="activity-list relative my-[6px] pb-4">
                                                                        {groupedActions[date].map((action) => {
                                                                            return (
                                                                                <li
                                                                                    className="h-[56px] flex justify-between p-[12px] pl-[42px]  hover:bg-unicon-activityhover"
                                                                                    key={action}
                                                                                >
                                                                                    <div className="inline-flex justify-start">
                                                                                        {action.action ==
                                                                                        'Chat Session Archived' ? (
                                                                                            <span className="w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBgearch">
                                                                                                <i className="icon icon-archived"></i>
                                                                                            </span>
                                                                                        ) : action.action ==
                                                                                          'Chat session deleted' ? (
                                                                                            <span className="w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBgdel">
                                                                                                <i className="icon icon-delete"></i>
                                                                                            </span>
                                                                                        ) : action.action ==
                                                                                          'Created chat session' ? (
                                                                                            <span className="w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBgearch">
                                                                                                <i className="icon icon-modal-edit"></i>
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span className="w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBglogged">
                                                                                                <i className="icon icon-logged"></i>
                                                                                            </span>
                                                                                        )}
                                                                                        <h5 className="pl-3 text-base font-medium text-unicon-lightBlue">
                                                                                            {action.action}
                                                                                            <br />
                                                                                            <span className="inline-flex items-center justify-start ">
                                                                                                <i className="icon icon-time"></i>
                                                                                                <span className="text-unicon-gray text-xs font-normal pl-[3px]">
                                                                                                    {formatTime(
                                                                                                        action.actionDate,
                                                                                                    )}
                                                                                                </span>
                                                                                            </span>
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div className="text-sm font-normal text-unicon-gray">
                                                                                        Chat ID:
                                                                                        <a className="text-unicon-blue pl-[3px] cursor-pointer">
                                                                                            <span className="font-semibold text-sm">
                                                                                                {action.id}
                                                                                            </span>
                                                                                        </a>
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </SettingModal>
                    </div> */}
                </div>
            </div>
        </div>
        <div className='bg-unicon-ChatSubHeaderColor h-[46px] w-full border-b border-solid border-unicon-grayBorder pl-[21px] pr-5 flex items-center justify-start gap-2.5' style={{boxShadow: '0px 2px 4px -4px rgba(16, 24, 40, 0.05)'}}>
        <i className='icon chat-icon'></i><span className='font-semibold text-lg text-unicon-lightBlue'>{selectedConversationTitle}</span>
        </div>
        
        </div>
    );

  
};

export default ChatHeader;
