import React, { useState } from 'react';
import withLayout from "../dashboard/WithLayout";
import MyuseslineChart from './MyuseslineChart';
import MyUsesGrid from './MyUsesGrid';
import MyUsesCardSlider from './MyUsesCardSlider';

const MyUses: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState<string>('30 days');
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleOptionSelect = (option: string): void => {
        setSelectedOption(option);
        setIsOpen(false);
        return; 
      };

    const seriesDataForUsage = [
        {
            name: 'Model 1',
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 158, 160, 170], // Steady increase
        },
        {
            name: 'Model 2',
            data: [15, 60, 70, 100, 120, 150, 180, 200, 250, 260, 280, 300], // Higher values, steeper increase
        },
        {
            name: 'Model 3',
            data: [25, 50, 80, 110, 140, 170, 200, 220, 250, 280, 300, 320], // Different curve, higher overall
        },
        {
            name: 'Model 4',
            data: [30, 80, 90, 120, 180, 240, 300, 350, 400, 420, 450, 480], // Highest values, aggressive growth
        },
    ];

    const users = [
        {
            id: 1,
            avatar: 'https://via.placeholder.com/150', // Placeholder image URL
            name: 'Albert Flore',
            email: 'albertflore@mail.com',
            amount: 6433,
        },
        {
            id: 2,
            avatar: 'https://via.placeholder.com/150',
            name: 'Jane Doe',
            email: 'janedoe@mail.com',
            amount: 7200,
        },
        {
            id: 3,
            avatar: 'https://via.placeholder.com/150',
            name: 'John Smith',
            email: 'johnsmith@mail.com',
            amount: 5400,
        },
        {
            id: 4,
            avatar: 'https://via.placeholder.com/150',
            name: 'Emma Johnson',
            email: 'emmajohnson@mail.com',
            amount: 8500,
        },
    ];
    return (
        <div className='pt-6'>
            <div className='h-[calc(100vh-135px)] overflow-auto scroll-div scroll-smooth scroll-p-0'>
            <div className='h-[150h] w-full  pb-2'>
                <MyUsesCardSlider/>
                </div>
            
                <div className="bg-white h-[360px] ml-2 mt-3 col-span-3 rounded-lg border border-solid border-unicon-grayBorder pl-3 pr-2 pt-1" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    {' '}
                    <MyuseslineChart modelName="Model Usage" series={seriesDataForUsage} height={300} />
                </div>

                <div className="bg-white col-span-3 ml-2 mt-5 px-5 pt-1 pb-5 rounded-lg border border-solid border-unicon-grayBorder" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    <MyUsesGrid />
                </div>
            
            </div>
           
        </div>
    );
};

const MyusesWithLayout = withLayout(MyUses,false);

export default MyusesWithLayout;
