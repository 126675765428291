import React, { useState, useEffect } from 'react';
import withLayout from '../dashboard/WithLayout';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import ProgressBar from '../utils/ProgressBar';
import Modal from '../utils/Modal';
import Microsoft from '../../assets/microsoft.svg';
import { ModelService } from '../../libs/services/ModelService';
import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import LoadingSpinner from '../../hooks/Loader';
import { toast } from 'react-toastify';

interface Node {
    id: string;
    name: string;
    provider: string;
    version: number;
    type: string;
    added_on: string;
    pool: number;
    amounts: number;
    token_limit: number;
    token_usage: number;
    pool_name: string;
    no_of_users: number;
    Token_Ent_yearly: number;
    Token_Ent_month: number;
}
interface Data {
    nodes: Node[];
}

const nodes: Node[] = [
    {
        id: '1',
        name: 'Model 1',
        provider: 'Microsoft',
        version: 3.5,
        type: 'Chat completion',
        added_on: 'Feb, 2024',
        pool: 2,
        amounts: 10000,
        token_limit: 10000,
        token_usage: 3.6,
        pool_name: 'Pool 1',
        no_of_users: 6,
        Token_Ent_yearly: 10000,
        Token_Ent_month: 833,
    },
    {
        id: '1',
        name: 'Model 1',
        provider: 'Microsoft',
        version: 3.5,
        type: 'Chat completion',
        added_on: 'Feb, 2024',
        pool: 2,
        amounts: 10000,
        token_limit: 10000,
        token_usage: 3.6,
        pool_name: 'Pool 2',
        no_of_users: 13,
        Token_Ent_yearly: 10000,
        Token_Ent_month: 833,
    },
    {
        id: '1',
        name: 'Model 1',
        provider: 'Microsoft',
        version: 3.5,
        type: 'Chat completion',
        added_on: 'Feb, 2024',
        pool: 2,
        amounts: 10000,
        token_limit: 10000,
        token_usage: 3.6,
        pool_name: 'Pool 3',
        no_of_users: 12,
        Token_Ent_yearly: 10000,
        Token_Ent_month: 833,
    },
];

function ModelsTable() {
    const { instance, inProgress } = useMsal();
    const modelService = new ModelService();
    const data: Data = { nodes };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>('Oct');
    const [isOpen, setIsOpen] = useState(false);
    const [modelsList, setmodelsList] = React.useState<any>([]);
    const [entitlementsForModel, setEntitlementsForModel] = useState<any>([]);
    const [modalOpenFor, setModalOpenFor] = useState<string>();
    const [currentModelDetails,setCurrentModelDetails] = useState<any>()
    const [entitlementsLoader, setentitlementsLoader] = useState(true)

    const openModal = (modelId) => {
        const currentModelDetails = modelsList.find((model) => {
            return model.id == modelId;
        });
        setCurrentModelDetails(currentModelDetails)
        setModalOpenFor(modelId);
        setIsModalOpen(true);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const [filterItems, setFilterItems] = useState([
        { id: 1, label: 'model 3' },
        { id: 2, label: 'model 4' },
    ]);
    const handleRemoveItem = (id: number) => {
        setFilterItems(filterItems.filter((item) => item.id !== id));
    };

    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    const getModelsList = async () => {
        modelService.getModelsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)).then((response) => {
            setmodelsList(response);
        });
    };

    const getEntitlementsForModel = async () => {
        setentitlementsLoader(true)
        modelService.getEntitlementsForModel(await AuthHelper.getSKaaSAccessToken(instance, inProgress),modalOpenFor).then((response) => {
            setEntitlementsForModel(response);
            setentitlementsLoader(false)
        });
    };

    useEffect(() => {
        getModelsList();
    }, []);

    useEffect(() => {
        if (modalOpenFor) getEntitlementsForModel();
    }, [modalOpenFor]);

    const [modelsDataForTable, setmodelsDataForTable] = useState<any>([]);
    const [entitlementsDataForTable, setEntitlementsDataForTable] = useState<any>([]);

    useEffect(() => {
        const tempData: any = { nodes: [] };
        modelsList.length > 0  && modelsList?.forEach((model) => {
            const date = new Date(model.createdOn);
            const formattedDate = date.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
            tempData.nodes.push({
                id: model.id,
                name: model.name,
                provider: model.provider,
                version: model.version,
                type: model.type,
                added_on: formattedDate,
                pool: model.poolModelEntitlements.length,
                amounts: null,
                token_limit: model.maxTokens,
                token_usage: null,
                pool_name: null,
                no_of_users: null,
                Token_Ent_yearly: null,
                Token_Ent_month: null,
            });
        });
        setmodelsDataForTable(tempData);
    }, [modelsList]);

    const [entitlementsToUpdateList,setEntitlementsToUpdateList] = useState<any>([])

    useEffect(() => {
        const tempData: any = { nodes: [] };
        entitlementsForModel.length > 0 && entitlementsForModel?.forEach((ent) => {
            tempData.nodes.push({
                entitlementId: ent.poolModelMappingId,
                poolId: ent.poolId,
                poolName: ent.poolName,
                users: ent.noOfUser,
                entitledTokenMonthly: ent.entitledTokenMonthly,
                entitledTokenYearly: ent.entitledTokenYearly,
                tokenUsage: ent.tokenUsage,
            });
        });
    
        setEntitlementsDataForTable(tempData);
    }, [entitlementsForModel]); 

    const onClose = () => {
        setCurrentModelDetails({})
        setIsModalOpen(false);
    };

    const getIfChecked = (poolId) => {
        const found = entitlementsToUpdateList.find((poolsToUpdate) => {
            return poolsToUpdate.poolId == poolId;
        });
        if(found) return true
        return false;
    }

    const handleCheck = (poolInfo) => {
        setEntitlementsToUpdateList((prevPoolsToUpdate) => {
            const isChecked = getIfChecked(poolInfo.poolId);
            if (isChecked) {
                return prevPoolsToUpdate.filter((pool) => pool.poolId !== poolInfo.poolId);
            }
            return [
                ...prevPoolsToUpdate,
                {
                    id: poolInfo.entitlementId,
                    modelId: currentModelDetails.id,
                    poolId: poolInfo.poolId,
                    entitledTokenYearly: poolInfo.entitledTokenYearly,
                    entitledTokenMonthly: poolInfo.entitledTokenMonthly,
                    tokenUsage: poolInfo.tokenUsage,
                    status: true,
                },
            ];
        });
    };

    const updateTokensForSpecificPool = (poolId, newMonthlyToken: any, newYearlyToken: any) => {
        if (newMonthlyToken) {
            newYearlyToken = newMonthlyToken * 12;
        } else {
            newMonthlyToken = newYearlyToken / 12;
        }
        const updated = entitlementsDataForTable.nodes?.map((pool) => {
            if (pool.poolId === poolId) {
                return {
                    ...pool,
                    entitledTokenMonthly: newMonthlyToken.toString(),
                    entitledTokenYearly: newYearlyToken.toString(),
                };
            }
            return pool;
        });
        setEntitlementsDataForTable({nodes:updated});
    };

    useEffect(() => {
        entitlementsDataForTable.nodes?.map((pool) => {
           entitlementsToUpdateList?.map((nested) => {
            if(nested.poolId == pool.poolId){
                nested.entitledTokenMonthly = pool.entitledTokenMonthly
                nested.entitledTokenYearly = pool.entitledTokenYearly
            }
           })
        })
    },[entitlementsDataForTable])

    const updateEntitlements = async () => {
        toast.success('Updating Entitlements');
        setIsModalOpen(false);
        try {
            await modelService
                .updateModelEntitlements(
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                    entitlementsToUpdateList,
                )
                .then((result: any) => {
                    toast.success('Entitlements Updated');
                });
        } catch (e: any) {
            const errorMessage = `Unable to Update Entitlements`;
            toast.error(errorMessage);
        } finally {
            getModelsList();
        }
    };


    return (
        <div className="px-[10px]">
            <div className="flex justify-between items-center h-[60px]">
                <div className='inputwrap h-10 flex items-end justify-start relative'>
                    <i className='icon icon-input-search absolute left-0 cursor-pointer bottom-1'></i>
                <input className="min-w-[332px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[26px] text-sm text-unicon-lightBlue font-medium" type="text" placeholder='Search users/roles..' />
                <button className={`w-auto  h-10 flex items-center justify-center relative  ${filterItems.length >= 1 ? 'filter-active-icon' : ''} `} style={{transition:'all 0.3s ease'}}>
                    <i className='icon icon-filter'></i>
                </button>
                </div>
                <div className="h-10 flex items-center justify-end">
                    <div className="inline-flex items-center justify-center border border-solid border-unicon-grayBorder rounded-md relative">
                        <button className="font-medium text-base text-unicon-lightBlue  px-[10px] bg-unicon-white h-10 ">
                            Yearly
                        </button>
                        <button
                            className=" px-[10px] flex items-center justify-between bg-unicon-white dropdown-button active-toggle h-10 "
                            onClick={toggleDropdown}
                        >
                            <span className="font-medium text-base text-unicon-lightBlue leading-4  w-[36px] ">
                                {' '}
                                {selectedOption}
                            </span>
                            <i className="icon icon-arrowdown"></i>
                        </button>
                    </div>
                    <button className="w-11 h-6 flex items-center justify-end ">
                        <i className="icon icon-excel"></i>
                    </button>
                </div>
            </div>
            <div
                className="border border-solid border-unicon-grayBorder rounded h-[calc(100vh-214px)]"
                style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
            >
                <div
                    className={`filter-list-wrpa flex justify-start py-3 px-6 min-h-[56px] ${filterItems.length === 0 ? 'hidden' : ''}`}
                    style={{ transition: 'all 0.3s ease' }}
                >
                    <span className="font-semibold text-base text-unicon-gray w-[65px] m-auto">Filters :</span>
                    <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[100px] flex-wrap w-[calc(100%-65px)]">
                        {filterItems?.map((item) => (
                            <li
                                key={item.id}
                                className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                            >
                                <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px] truncate">
                                    {item.label}
                                </span>
                                <i
                                    className="icon icon-close-pills cursor-pointer"
                                    onClick={() => {
                                        handleRemoveItem(item.id);
                                    }}
                                ></i>
                            </li>
                        ))}
                    </ul>
                </div>
              
                {modelsDataForTable?.nodes?.length > 0 ? (
                    <Table
                        data={modelsDataForTable}
                        theme={theme}
                        className="w-full rounded  max-h-[calc(100vh-214px)] scroll-div scroll-smooth scroll-p-0"
                    >
                        {(tableList: any[]) => (
                            <>
                                <Header className="">
                                    <HeaderRow className="">
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Name
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Provider
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Version
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Type
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Added On
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            # Pools
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Amount (USD)
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Token Limit
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Token Usage
                                        </HeaderCell>
                                    </HeaderRow>
                                </Header>

                                <Body>
                                    {tableList?.map((item) => (
                                        <React.Fragment key={item.id}>
                                            <Row item={item}>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    <div
                                                        className="flex items-center justify-start cursor-pointer"
                                                        onClick={() => {
                                                            openModal(item.id);
                                                        }}
                                                    >
                                                        <img
                                                            src={Microsoft}
                                                            alt="microsoft"
                                                            className="w-[22px] h-[22px] pr-[5px]"
                                                        />
                                                        <span className="text-unicon-lightBlue font-medium text-base block overflow-hidden text-ellipsis max-w-[124px]">
                                                            {item.name}
                                                        </span>
                                                    </div>
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    onClick={() => {
                                                        openModal(item.id);
                                                    }}
                                                >
                                                    {item.provider}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    onClick={() => {
                                                        openModal(item.id);
                                                    }}
                                                >
                                                    {item.version}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    onClick={() => {
                                                        openModal(item.id);
                                                    }}
                                                >
                                                    {item.type}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    onClick={() => {
                                                        openModal(item.id);
                                                    }}
                                                >
                                                    {item.added_on}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    onClick={() => {
                                                        openModal(item.id);
                                                    }}
                                                >
                                                    {item.pool}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    onClick={() => {
                                                        openModal(item.id);
                                                    }}
                                                >
                                                    {item.amounts}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    onClick={() => {
                                                        openModal(item.id);
                                                    }}
                                                >
                                                    {item.token_limit}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    onClick={() => {
                                                        openModal(item.id);
                                                    }}
                                                >
                                                    <ProgressBar
                                                        value={item.token_usage ? item.token_usage : 0}
                                                        max={100}
                                                    />
                                                </Cell>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                </Body>
                            </>
                        )}
                    </Table>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
            <footer className="font-medium text-base text italic h-10 flex items-center pl-2">
                <span className="text-[#C32B0A] text-xxl font-semibold">*</span> To add new model please reach out to{' '}
                <span className="not-italic text-unicon-blue pl-[2px]"> ChatBolt administrator</span>.
            </footer>

            <Modal
                isOpen={isModalOpen}
                onClose={onClose}
                showIcon={true}
                title={currentModelDetails?.name}
                subtitle="Chat completion"
            >
                <div className="modalbody w-full relative h-auto">
                    <div className="modal-content-wrap h-auto py-[30px] px-[30px]">
                        <h2 className="font-medium text-unicon-lightBlue text-xl mb-3">Pool Entitlement</h2>
                        <div
                            className="border border-solid border-unicon-grayBorder rounded h-[calc(90vh-220px)] 2xl:h-[calc(90vh-420px)] xl:h-[calc(90vh-350px)]"
                            style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
                        >
                            <Table data={data} theme={theme} className="w-full rounded ">
                                {(tableList: Node[]) => (
                                    <>
                                        <Header className="">
                                            <HeaderRow className="">
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    Pool Name
                                                </HeaderCell>
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    No of Users
                                                </HeaderCell>
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    Token Ent. (Yearly )
                                                </HeaderCell>
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    {' '}
                                                    Token Ent. (Monthly )
                                                </HeaderCell>
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    Token Usage
                                                </HeaderCell>
                                            </HeaderRow>
                                        </Header>

                                        <Body>
                                            {entitlementsDataForTable?.nodes?.length > 0 && !entitlementsLoader &&
                                                entitlementsDataForTable.nodes?.map((item) => (
                                                    <React.Fragment key={item.id}>
                                                        <Row item={item}>
                                                            <Cell className="text-unicon-lightBlue font-medium text-base">
                                                                <div className='flex items-center justify-start'>
                                                                <input
                                                                    type="checkbox"
                                                                    className="mr-2 w-[18px] h-[18px] rounded-sm bg-unicon-primaryColor cursor-pointer"
                                                                    checked={getIfChecked(item.poolId)}
                                                                    onChange={() => {
                                                                        handleCheck(item);
                                                                    }}
                                                                />
                                                               <span className='text-unicon-lightBlue font-medium text-base'>{item.poolName}</span>
                                                               </div>
                                                            </Cell>
                                                            <Cell className="text-unicon-lightBlue font-medium text-base text-right">
                                                                {item.users}
                                                            </Cell>
                                                            <Cell className="text-unicon-lightBlue font-medium text-base">
                                                                <input
                                                                    value={item.entitledTokenYearly}
                                                                    onChange={(e) => {
                                                                        updateTokensForSpecificPool(
                                                                            item.poolId,
                                                                            null,
                                                                            e.target.value,
                                                                        );
                                                                    }}
                                                                    className="w-full text-right border-b border-solid border-unicon-blue bg-transparent  px-[14px] focus-visible:outline-none  focus-visible:text-left focus-visible:border-b focus-visible:border-solid focus-visible:border-unicon-modalBgearch"
                                                                />
                                                            </Cell>
                                                            <Cell className="text-unicon-lightBlue font-medium text-base">
                                                                <input
                                                                    value={item.entitledTokenMonthly}
                                                                    onChange={(e) => {
                                                                        updateTokensForSpecificPool(
                                                                            item.poolId,
                                                                            e.target.value,
                                                                            null,
                                                                        );
                                                                    }}
                                                                    className="w-full text-right border-b border-solid border-unicon-blue bg-transparent  px-[14px] focus-visible:outline-none  focus-visible:text-left focus-visible:border-b focus-visible:border-solid focus-visible:border-unicon-modalBgearch"
                                                                />
                                                            </Cell>
                                                            <Cell className="text-unicon-lightBlue font-medium text-base">
                                                                <ProgressBar value={item.tokenUsage} max={100} />
                                                            </Cell>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                            {entitlementsLoader && <LoadingSpinner className="h-full w-full" />}
                                            {!entitlementsLoader && entitlementsDataForTable?.nodes?.length == 0 && (
                                                <div className='w-[600px] text-right h-[400px] text-lg font-medium mt-40'>No Entitlements Found For This Model</div>
                                            )}
                                        </Body>
                                    </>
                                )}
                            </Table>
                        </div>
                    </div>
                    <div className="modal-footer border-t border-solid border-unicon-modalBorder flex items-center justify-end gap-4 w-full h-20 rounded-b-xl px-[30px]">
                        <button
                            className="btn secondary-btn"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn"
                            onClick={() => {
                                updateEntitlements()
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
const ModelsTableLayout = withLayout(ModelsTable,true);

export default ModelsTableLayout;
