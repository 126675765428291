import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick slider styles
import "slick-carousel/slick/slick-theme.css"; // Import slick slider theme styles
import Chartlineimg from '../../assets/Chart-line.svg';
import ChartDownlineimg from'../../assets/down-line-chart.svg';


export default function SimpleSlider() {
    const [selectedOption, setSelectedOption] = useState<string>('30 days');
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleOptionSelect = (option: string): void => {
        setSelectedOption(option);
        setIsOpen(false);
        return; 
      }; 
  const settings = {
    dots: false, 
    infinite: true, 
    speed: 500, 
    slidesToShow: 3, 
    slidesToScroll: 1 ,
    arrows: true,
    responsive: [
        {
            breakpoint: 1240, 
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1146, 
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768, // Screen width <= 768px
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480, // Screen width <= 480px
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
  };

  return (
    <div className="w-full ">
      <Slider {...settings}>
            <div className="bg-white  h-[150px] rounded-lg  border border-solid border-unicon-grayBorder p-5 " style={{boxShadow: '0px 2px 10px 0px #7090B00F'}}>
                        <div className='flex items-center justify-between'>
                            <div className='inline-flex items-center justify-start'>
                                <i className='icon icon-microsoft'></i>
                                <span className='text-unicon-lightBlue text-base font-medium  px-1'>Model 1</span>
                                <i className='icon icon-modelinfo'></i>
                                </div>
                                <div className='relative'>
                                    <button className='bg-unicon-daysDropdown rounded pl-2 px-1 flex items-center justify-between h-[20px]' onClick={toggleDropdown}>
                                        <span className='font-normal text-sm text-unicon-gray leading-4  min-w-[36px] pr-1'>{selectedOption}</span><i className='icon icon-arrowdown'></i>
                                    </button>
                                {isOpen && (
                                    <ul className="py-2 absolute top-5 right-0 w-full border border-solid border-unicon-daysDropdown z-20 bg-white" style={{boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808'}}>
                                    <li className=" cursor-pointer flex items-center justify-start p-2  font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('30 days'); }}
                                    >30 days</li>
                                    <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('14 days'); }}
                                    >14 days</li>
                                    <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue"  onClick={() => { handleOptionSelect('10 days'); }}
                                    >10 days</li>
                                    
                                    </ul>
                            )}
                                </div>
                            </div>  
                            <div className='flex items-center justify-between mt-[36px]'>
                                <div className='inner-left'>
                                    <div className='font-semibold text-xxl text-unicon-lightBlue whitespace-nowrap'>$2,433 <span className='font-medium text-base'>($10,000)</span>
                                    <span className='bg-unicon-trendBg inline-flex items-center justify-center h-[19px] px-[6px] gap-x-1 rounded ml-2'>
                                        <i className='icon iconTreandUp'></i>
                                        <span className='text-sm font-medium text-unicon-green'>1.22%</span>
                                    </span>
                                    </div>
                                    <div className='inline-flex items-center justify-start pt-1.5'>
                                        <i className='icon icon-currency'></i><span className='text-unicon-gray font-medium text-base'>3,500 <span className='text-sm'>(10,000)</span>
                                        </span>
                                    </div>
                                </div> 
                                <div className='inner-right w-[70px] h-[60px] relative'>
                                    <img src={Chartlineimg} alt="line"/>
                                </div> 
                            </div>
            </div>
            <div className="bg-white  h-[150px] rounded-lg  border border-solid border-unicon-grayBorder p-5 " style={{boxShadow: '0px 2px 10px 0px #7090B00F'}}>
                  <div className='flex items-center justify-between'>
                    <div className='inline-flex items-center justify-start'>
                        <i className='icon icon-microsoft'></i>
                        <span className='text-unicon-lightBlue text-base font-medium  px-1'>Model 1</span>
                        <i className='icon icon-modelinfo'></i>
                        </div>
                        <div className='relative'>
                            <button className='bg-unicon-daysDropdown rounded pl-2 px-1 flex items-center justify-between h-[20px]' onClick={toggleDropdown}>
                                <span className='font-normal text-sm text-unicon-gray leading-4  min-w-[36px] pr-1'>{selectedOption}</span><i className='icon icon-arrowdown'></i>
                            </button>
                        {isOpen && (
                            <ul className="py-2 absolute top-5 right-0 w-full border border-solid border-unicon-daysDropdown z-20 bg-white" style={{boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808'}}>
                            <li className=" cursor-pointer flex items-center justify-start p-2  font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('30 days'); }}
                            >30 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('14 days'); }}
                            >14 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue"  onClick={() => { handleOptionSelect('10 days'); }}
                            >10 days</li>
                            
                            </ul>
                    )}
                        </div>
                    </div>  
                    <div className='flex items-center justify-between mt-[36px]'>
                        <div className='inner-left'>
                            <div className='font-semibold text-xxl text-unicon-lightBlue whitespace-nowrap'>$2,433 <span className='font-medium text-base'>($10,000)</span>
                            <span className='bg-unicon-trendBg inline-flex items-center justify-center h-[19px] px-[6px] gap-x-1 rounded ml-2'>
                                <i className='icon iconTreandUp'></i>
                                <span className='text-sm font-medium text-unicon-green'>1.22%</span>
                            </span>
                            </div>
                            <div className='inline-flex items-center justify-start pt-1.5'>
                                <i className='icon icon-currency'></i><span className='text-unicon-gray font-medium text-base'>3,500 <span className='text-sm'>(10,000)</span>
                                </span>
                            </div>
                        </div> 
                        <div className='inner-right w-[70px] h-[60px] relative'>
                            <img src={Chartlineimg} alt="line"/>
                        </div> 
                    </div>
            </div>
            <div className="bg-white  h-[150px] rounded-lg  border border-solid border-unicon-grayBorder p-5 " style={{boxShadow: '0px 2px 10px 0px #7090B00F'}}>
                  <div className='flex items-center justify-between'>
                    <div className='inline-flex items-center justify-start'>
                        <i className='icon icon-microsoft'></i>
                        <span className='text-unicon-lightBlue text-base font-medium  px-1'>Model 3</span>
                        <i className='icon icon-modelinfo'></i>
                        </div>
                        <div className='relative'>
                            <button className='bg-unicon-daysDropdown rounded pl-2 px-1 flex items-center justify-between h-[20px]' onClick={toggleDropdown}>
                                <span className='font-normal text-sm text-unicon-gray leading-4  min-w-[36px] pr-1'>{selectedOption}</span><i className='icon icon-arrowdown'></i>
                            </button>
                        {isOpen && (
                            <ul className="py-2 absolute top-5 right-0 w-full border border-solid border-unicon-daysDropdown bg-white z-20" style={{boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808'}}>
                            <li className=" cursor-pointer flex items-center justify-start p-2  font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('30 days'); }}
                            >30 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('14 days'); }}
                            >14 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue"  onClick={() => { handleOptionSelect('10 days'); }}
                            >10 days</li>
                            
                            </ul>
                    )}
                        </div>
                    </div>  
                    <div className='flex items-center justify-between mt-[36px]'>
                        <div className='inner-left'>
                            <div className='font-semibold text-xxl text-unicon-lightBlue whitespace-nowrap'>$2,433 <span className='font-medium text-base'>($10,000)</span>
                            <span className='bg-unicon-trendDownBg inline-flex items-center justify-center h-[19px] px-[6px] gap-x-1 rounded ml-2'>
                                <i className='icon iconTreandDownIcon'></i>
                                <span className='text-sm font-medium text-unicon-trendDownColor'>2.90%</span>
                            </span>
                            </div>
                            <div className='inline-flex items-center justify-start pt-1.5'>
                                <i className='icon icon-currency'></i><span className='text-unicon-gray font-medium text-base'>3,500 <span className='text-sm'>(10,000)</span>
                                </span>
                            </div>
                        </div> 
                        <div className='inner-right w-[70px] h-[60px]'>
                            <img src={ChartDownlineimg} alt="line"/>
                        </div> 
                    </div>
            </div>
           
            
            
      </Slider>
    </div>
  );
}
