import { Model } from "../models/ModelsType";
import { BaseService } from "./BaseService";

export class ModelService extends BaseService {
    public getModelsAsync = async (accessToken:string): Promise<Model[]> => {
       
        const result = await this.getResponseAsync<Model[]>(
            {
                commandPath: 'chatmodel',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
    
    public getUserEntitledModels = async (accessToken:string,userId): Promise<Model[]> => {
       
        const result = await this.getResponseAsync<Model[]>(
            {
                commandPath: `userentitlement/${userId}`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public getEntitlementsForModel = async (accessToken:string,modelId:any): Promise<Model[]> => {
        const result = await this.getResponseAsync<any[]>(
            {
                commandPath: `modelpoolentitlement/${modelId}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };

    public getMyModels = async (accessToken:string,userId:any): Promise<any[]> => {
        const result = await this.getResponseAsync<any[]>(
            {
                commandPath: `chatmodel/${userId}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };


    public updateModelEntitlements = async (accessToken,newEntitlementsArray:any): Promise<any> => {
        const body:any = newEntitlementsArray
        const result = await this.getResponseAsync<any>(
            {
                commandPath: `model/updatemodelpools`,
                method: 'PATCH',
                body,
            },
            accessToken,
        );

        return result;
    };
}
