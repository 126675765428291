import withLayout from '../dashboard/WithLayout';
import myModelsImg from '../../assets/microsoft.svg';
import { UsersService } from '../../libs/services/UsersService';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import { ModelService } from '../../libs/services/ModelService';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../hooks/Loader';

const MyModel: React.FC = () => {
    const { instance, inProgress } = useMsal();
    const modelService = new ModelService();
    const [modelsList, setmodelsList] = useState<any>([]);
    const [loader, setloader] = useState(false);

    const getMyModelsList = async () => {
        setloader(true)
        modelService.getModelsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)).then((response) => {
            setmodelsList(response);
            setloader(false);

        });
    };

    useEffect(() => {
        getMyModelsList();
    }, []);

    if (loader) {
        return <LoadingSpinner/>;
    }

    return (
        <div className="pt-2 bg-unicon-white">
            <div className="flex justify-between items-center h-[60px] px-[4px]">
                <div className="inputwrap h-10 flex items-end justify-start relative">
                    <i className="icon icon-input-search absolute left-0 cursor-pointer bottom-1"></i>
                    <input
                        className="min-w-[332px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[26px] text-sm text-unicon-lightBlue font-medium"
                        type="text"
                        placeholder="Search chats.."
                    />
                </div>
                <div className="flex items-center justify-end">
                    <div
                        className="min-w-[170px] h-[36px] flex items-center justify-start rounded-lg border border-solid border-unicon-grayBorder gap-[6px] pl-[9px] pr-[14px]"
                        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                    >
                        <i className="icon icon-calendar cursor-pointer"></i>
                        <input
                            className="text-unicon-lightBlue text-[13px] font-medium h-8 focus-visible:outline-none"
                            type="text"
                            value="Feb 19- Feb 29, 2024"
                            readOnly
                        />
                    </div>
                </div>
            </div>
            <div
                className=" rounded bg-unicon-settingBgColor border border-solid  border-unicon-grayBorder  py-7 "
                style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)' }}
            >
                <ul className="gap-6 flex flex-col overflow-x-hidden overflow-auto scroll-div px-7 h-[calc(100vh-235px)]">
                    {modelsList.map((model) => {
                        return (
                            <li
                                className="bg-unicon-white border border-solid border-unicon-grayBorder rounded px-4 pt-1 pb-3"
                                style={{ boxShadow: '0px 2px 10px 0px #7090B00F' }}
                                key={model.id}
                            >
                                <div className="h-[42px] w-full flex items-center justufy-start">
                                    <span className="w-[42px] h-[42px]">
                                        <img className="w-full h-full" src={model.modelIconUrl} alt="My Model" />
                                    </span>
                                    <h4 className="font-semibold text-unicon-lightBlue text-base leading-[18px] pl-1">
                                        {model.name}
                                        <br />
                                        <span className="font-normal text-Hsm text-unicon-moreFontColor leading-none">
                                            {model.type}
                                        </span>
                                        <span className="pl-1 leading-none font-medium text-[11px] text-unicon-lightBlue">
                                            {model.version}
                                        </span>
                                    </h4>
                                </div>
                                <div className="font-normal text-unicon-gray  text-Hsm pt-[6px] pb-[15px]">
                                    {model.description}
                                </div>
                                <div className="cardFooter w-full flex items-end justify-between  border-solid border-t border-[rgba(234, 236, 240, 0.6)] pt-3">
                                    <div className="flex items-center justify-start">
                                        <i className="icon icon-chat-count"></i>
                                        <span className="text-xl text-unicon-lightBlue font-medium">
                                            {model.poolModelEntitlements.length}
                                            <span className="font-normal text-sm">chat counts</span>
                                        </span>
                                    </div>

                                    <div className="flex items-center justify-start">
                                        <i className="icon icon-chat-tokens"></i>
                                        <span className="text-xl text-unicon-lightBlue font-medium">
                                            {model.maxTokens}
                                            <span className="font-normal text-sm">tokens</span>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

const MyModelWithLayout = withLayout(MyModel,false);

export default MyModelWithLayout;
