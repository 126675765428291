import React from 'react';
import Close from '../../assets/modal_cross.svg';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const SettingModal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50" onClick={onClose} />
            <div className="setting-modal-responsive bg-white rounded-xl w-[calc(100%-10rem)] h-[calc(100vh-10.6rem)]  z-10 2xl:w-[calc(100%-50rem)] xl:w-[calc(100%-20rem)] " style={{boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)'}}>
                <div className="flex w-full justify-between items-center rounded-t-xl border-b border-solid border-unicon-modalBorder h-[60px] px-[30px]">
                    <h2 className="text-[18px] text-unicon-lightBlue font-semibold leading-[28px]">{title}</h2>
                    <img src={Close} alt="" onClick={onClose} className="cursor-pointer" />
                </div>
                <div className='w-full'>{children}</div>
            </div>
        </div>
    );
};

export default SettingModal;
