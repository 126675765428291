import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NoEntitlements: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="bg-unicon-dashboardBgColor h-screen w-full  flex  items-center justify-start px-[100px]">
            <div className="flex  justify-start flex-col">
                <h2 className=" font-semibold text-unicon-lightBlue text-[40px] leading-10 text-left">
                    No Entitlements
                </h2>
                <p className="max-w-[929px] font-medium text-xxl text-unicon-gray mx-auto pt-[25px] leading-9 text-left">
                    You don&#39;t have entitlements on this page. Please reach out to the administrator.
                </p>
                <div className="pt-[70px] flex justify-start flex-col">
                    <button
                        className="h-11 w-[289px] rounded-[45px]  bg-unicon-primaryColor  text-unicon-white font-medium text-xl leading-6"
                        data-testid="signinButton"
                        onClick={() => {
                            navigate(`/`);
                        }}
                    >
                        Back To Dashboard
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NoEntitlements;
