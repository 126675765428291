import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

function Donutchart() {
    const options: ApexOptions = {
        chart: {
            type: 'donut',
            width: 'auto', 
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '60%', 
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                        },
                        value: {
                            show: true,
                            fontSize: '22px',
                            fontWeight: 600,
                            color: '#1B2559',
                            fontFamily: 'Plus Jakarta Sans',
                            offsetY: 3,
                            formatter: () => "$80,056"
                        },
                        total: {
                            show: true,
                            label: 'Total Value',
                            fontSize: '12px',
                            fontWeight: 500,
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#718096',
                            formatter: () => "$80,056"
                        }
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 5,
            fontSize: '12px',
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 500,
            labels: {
                colors: "#1B2559",
            },
            markers: {
                shape: "circle",
                offsetX: -5,
            },
            itemMargin: {
                horizontal: 15,
            }
            // formatter: (seriesName) => {
            //     // Only return the series name without the value (percentage)
            //     return `${seriesName}`;
            // }
        },
        tooltip: {
            enabled: true,
            custom: ({ series, seriesIndex, w }) => {
                const poolNames = ["Pool1", "Pool2", "Pool3"];
                const userImages = `
                    <div style="display: flex; gap: 5px;">
                        <i  class="icon icon-pool-avtimg" style="width: 24px; height: 24px; border-radius: 50%; box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;"></i>
                        <i  class="icon icon-pool-avtimg" style="width: 24px; height: 24px; border-radius: 50%; margin-left:-12px; border:1.5px solid #fff; box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;"></i>
                        <i  class="icon icon-pool-avtimg" style="width: 24px; height: 24px; border-radius: 50%; margin-left:-12px; border:1.5px solid #fff; box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;"></i>
                        <i  class="icon icon-pool-avtimg" style="width: 24px; height: 24px; border-radius: 50%; margin-left:-12px; border:1.5px solid #fff; box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;"></i>
                        <button class="donut-tooltip-button"><i class="icon icon-plus"></i>5</button>
                    </div>`;
                return `<div style=" padding:5px 10px!important; background-color:#fff; box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814; color:#1B2559";>
                            <div style="font-weight:500; font-size:14px; background-color:#fff; box-shadow:box-shadow: 0px 2px 10px 2px #7090B00F; color:#1B2559">
                            ${poolNames[seriesIndex]}
                            </div>
                            <hr style="margin-top:4px;">
                            <div style="padding-top:6px; background-color:#fff; box-shadow:box-shadow: 0px 2px 10px 2px #7090B00F; color:#1B2559; font-size:12px; font-weight:500; display:flex; align-item:center; justify-content:space-between; gap:20px;">Total Usage:<span style="font-weight:700;"> $${series[seriesIndex]}</span></div>
                            <div style="padding-top:4px; background-color:#fff; box-shadow:box-shadow: 0px 2px 10px 2px #7090B00F; color:#1B2559; font-size:12px; font-weight:500;">Users:</div>
                            <div style="padding:6px 0px 5px; background-color:#fff; box-shadow:box-shadow: 0px 2px 10px 2px #7090B00F; color:#1B2559">${userImages}</div>
                        </div>`;
            }
        },
        colors: ["#FFBD62", "#5595F6", "#00AEA4"], 
        stroke: {
            width: 0,
        },
        labels: ["Pool1", "Pool2", "Pool3"]
    };

    const series = [44, 55, 33]; 
    return (
        <div className="py-4 px-5">
            <div className="flex items-center justify-between">
                <h3 className="font-semibold text-unicon-lightBlue text-xl flex items-center justify-start">
                    Run Rate Grid
                    <i className="icon icon-modelinfo ml-1"></i>
                </h3>
                <div className="flex items-center justify-between border border-solid border-unicon-grayBorder rounded-md">
                    <button className="h-[30px] w-[30px] flex items-center justify-center rounded-l bg-unicon-white text-base active-btn">$</button>
                    <button className="h-[30px] w-[30px] flex items-center justify-center rounded-r bg-unicon-white">
                        <i className="icon icon-currency"></i>
                    </button>
                </div>
            </div>
            <div className='pt-6' style={{ maxWidth: '330px', margin: '0 auto' }}>
                <ReactApexChart options={options} series={series} type="donut"  height={260} />
            </div>
        </div>
    );
}

export default Donutchart;
