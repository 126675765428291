import { makeStyles } from '@fluentui/react-components';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import ChatList from '../chat/chat-list/ChatList';
import ChatWindow from '../chat/ChatWindow';
import { useMsal } from '@azure/msal-react';
import { ModelService } from '../../libs/services/ModelService';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { setSelectedModel, setModelsList } from '../../redux/features/models/modelsSlice';


const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
        position:'relative'
    },
});

const ChatView: FC = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const modelService = new ModelService();
    const { instance, inProgress } = useMsal();

    const getModels = async () => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        const models = await modelService.getUserEntitledModels(accessToken, activeUserInfo?.id?.split('.')[0]);
        dispatch(setSelectedModel(models[0]));
        dispatch(setModelsList(models));
    };

    useEffect(() => {
        getModels();
    }, [instance, inProgress]);

    return (
        <div className={classes.container}>
            <ChatList />
            <ChatWindow />

        </div>
    );
};

export default ChatView;
