import { useCallback, useEffect, useRef, useState } from 'react';
import add from '../../../assets/add.svg';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { getFriendlyChatName, useChat } from '../../../libs/hooks/useChat';
import { ChatListSection } from './ChatListSection';
import Modal from '../../utils/Modal';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { PoolService } from '../../../libs/services/PoolService';
import Close from '../../../assets/modal_cross.svg';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import { ChatService } from '../../../libs/services/ChatService';
import { GroupChatListSection } from './GroupChatListSection';
import Avatar from '../../../assets/Avatar.svg';
import debounce from 'lodash.debounce';
import { UsersService } from '../../../libs/services/UsersService';
import { getInitials } from '../../utils/Helper';
import LoadingSpinner from '../../../hooks/Loader';

interface ConversationsView {
    pinnedCoversations?: Conversations;
    chatConversations?: Conversations;
    groupConversations?: Conversations;
}

function ChatList() {
    const chat = useChat();
    const [isFocused, setIsFocused] = useState(false);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const userId = activeUserInfo?.id ?? '';
    const { conversations } = useAppSelector((state: RootState) => state.conversations);
    const [filterText, setFilterText] = useState('');
    const [conversationsView, setConversationsView] = useState<ConversationsView>({
        chatConversations: conversations,
    });
    const [isCreatingNewChat, setisCreatingNewChat] = useState(false);
    const [isCreateGroupModalOpen, setisCreateGroupModalOpen] = useState(false);

    const sortConversations = (conversations: Conversations): ConversationsView => {
        const sortedIds = Object.keys(conversations).sort((a, b) => {
            const firstMessageA = conversations[a].messages[0];
            const firstMessageB = conversations[b].messages[0];
            if (!firstMessageA || !firstMessageB || !firstMessageA.timestamp || !firstMessageB.timestamp) {
                return firstMessageA && !firstMessageB ? -1 : 1;
            }
            return new Date(firstMessageB.timestamp).getTime() - new Date(firstMessageA.timestamp).getTime();
        });

        const chatConversations: Conversations = {};
        const pinnedConversions: Conversations = {};
        const groupConversations: Conversations = {};

        sortedIds.forEach((id) => {
            const isPinned = conversations[id].users.some((user) => user.id === userId && user.isPinned);
            const isGroupType = conversations[id].sessionType == 'Group';

            if (isPinned) {
                pinnedConversions[id] = conversations[id];
            }

            if (isGroupType) {
                groupConversations[id] = conversations[id];
            } else {
                chatConversations[id] = conversations[id];
            }
        });

        return {
            pinnedCoversations: pinnedConversions,
            chatConversations: chatConversations,
            groupConversations: groupConversations,
        };
    };

    useEffect(() => {
        const nonHiddenConversations: Conversations = {};
        for (const key in conversations) {
            const conversation = conversations[key];
            if (conversation.title.toLowerCase().includes(filterText.toLowerCase())) {
                nonHiddenConversations[key] = conversation;
            }
        }
        setConversationsView(sortConversations(nonHiddenConversations));
    }, [conversations, filterText]);

    const onAddChat = () => {
        toast.success('Creating New Chat');
        setisCreatingNewChat(true);
        void chat
            .createChat()
            .then((res) => {
                toast.success('New Chat Created!');
            })
            .catch((e) => {
                console.log(e);
                toast.error('Error While Creating New Chat');
            });
        setTimeout(() => {
            setisCreatingNewChat(false);
        }, 1500);
    };

    const onClose = () => {
        settopicValue('');
        setgroupValue('');
        setselectedOwners([]);
        setselectedOwnersIds([]);
        setselectedUsersIds([]);
        setIsFocused(false);
        setselectedUsers([]);
        setisCreateGroupModalOpen(false);
    };

    const togglePoolsDropdown = () => {
        setisUsersDropdownOpen(false);
        setisPoolsDropdownOpen((drpdown) => !drpdown);
    };

    const toggleUsersDropdown = () => {
        setisPoolsDropdownOpen(false);
        setisUsersDropdownOpen((drpdown) => !drpdown);
    };

    const [isPoolsDropdownOpen, setisPoolsDropdownOpen] = useState(false);
    const [isUsersDropdownOpen, setisUsersDropdownOpen] = useState(false);
    const [isCategoryDropdown, setisCategoryDropdown] = useState(false);
    const [selectedPools, setselectedPools] = useState<any>();
    const [selectedCategory, setselectedCategory] = useState<any>('');
    const [selectedUsers, setselectedUsers] = useState<any>([]);
    const [selectedOwners, setselectedOwners] = useState<any>([]);
    const [poolsList, setPoolsList] = useState<any>([]);
    const [usersList, setusersList] = useState<any>([]);
    const [topicValue, settopicValue] = useState('');
    const [groupValue, setgroupValue] = useState('');
    const [groupDescription, setgroupDescription] = useState('');

    const handleSelectUser = (user) => {
        setselectedUsers([...selectedUsers, user]);
    };

    const handleRemoveFromSelectedPools = (pool) => {
        setselectedPools({});
    };

    const handleRemoveFromSelectedUsers = (user) => {
        const tempObj = selectedUsers.filter((selectedUser) => {
            return selectedUser.id != user.id;
        });
        setselectedUsers(tempObj);
    };

    const getCheckedValue = (pool) => {
        return pool.id == selectedPools?.id;
    };

    const getCheckedValueForUser = (user) => {
        const tempObj = [...selectedUsers];
        const userAlreadyExist = tempObj.find((selectedUser) => selectedUser.id === user.id);
        return !!userAlreadyExist;
    };

    const toggleSelectedPools = (pool) => {
        console.log(pool.id, selectedPools?.id);
        if (pool.id == selectedPools?.id) {
            setselectedPools({});
        } else {
            setselectedPools(pool);
            setisPoolsDropdownOpen(false);
        }
    };

    const toggleSelectedCategory = (cat) => {
        if (cat == selectedCategory) {
            setselectedCategory('');
        } else {
            setselectedCategory(cat);
            setisCategoryDropdown(false);
        }
    };

    const toggleUser = (user) => {
        setsearchedUsers([]);
        setKeyword('');
        if (currentSearchTab == 1) {
            setselectedUsers((prevUsers) => {
                const userExists = prevUsers.some((existingUser) => existingUser.id === user.id);
                if (userExists) {
                    return prevUsers.filter((existingUser) => existingUser.id !== user.id);
                } else {
                    return [...prevUsers, user];
                }
            });
        } else {
            setselectedOwners((prevUsers) => {
                const userExists = prevUsers.some((existingUser) => existingUser.id === user.id);
                if (userExists) {
                    return prevUsers.filter((existingUser) => existingUser.id !== user.id);
                } else {
                    return [...prevUsers, user];
                }
            });
        }
    };

    const poolService = new PoolService();
    const chatService = new ChatService();

    const { instance, inProgress } = useMsal();

    const getPoolsList = async () => {
        poolService.getPoolsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)).then((response) => {
            setPoolsList(response);
        });
    };

    useEffect(() => {
        getPoolsList();
    }, []);

    useEffect(() => {
        const tempArray: any = [];
        setusersList(tempArray);
    }, [selectedPools]);

    useEffect(() => {
        const poolInfo = poolsList.filter((pool) => {
            return pool?.id == selectedPools?.id;
        });
        if (poolInfo.length > 0) {
            setusersList(poolInfo[0]?.users);
        }
    }, [selectedPools]);

    const [selectedUsersIds, setselectedUsersIds] = useState<any>([]);
    const [selectedOwnersIds, setselectedOwnersIds] = useState<any>([]);

    useEffect(() => {
        const tempArray: any = [];
        selectedUsers.map((user) => {
            tempArray.push(`${user?.id}.412259ed-cfdd-49be-8186-814dfe58dc54`);
        });
        setselectedUsersIds(tempArray);
    }, [selectedUsers]);

    useEffect(() => {
        const tempArray: any = [];
        selectedOwners.map((user) => {
            tempArray.push(`${user?.id}.412259ed-cfdd-49be-8186-814dfe58dc54`);
        });
        setselectedOwnersIds(tempArray);
    }, [selectedUsers]);

    const { selectedModel } = useAppSelector((state: RootState) => state.models);

    const modelId = selectedModel.modelId;
    const poolId = selectedModel.poolId;

    const body = {
        title: topicValue,
        poolid: poolId,
        ModelId: modelId,
        participantsIds: [activeUserInfo?.id, ...selectedUsersIds, ...selectedOwnersIds],
        ownerIds: [activeUserInfo?.id, ...selectedOwnersIds],
        SessionType: 'Group',
        GroupName: groupValue,
        ChatType: 'Private',
    };

    const createGroupChat = async () => {
        if (topicValue === '' || selectedUsersIds.length === 0 || !activeUserInfo?.id || !groupValue) {
            toast.error('Please make sure all fields are filled.');
            return;
        }
        onClose();

        toast.success('Creating Group Chat...');
        try {
            await chatService.createGroupChat(body, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
            await chat.loadChats();
            toast.success('Group Chat Created');
        } catch (e: any) {
            const errorMessage = `Unable to create group chat: ${e.message || 'Unknown error'}`;
            toast.error(errorMessage);
        }
    };

    const [isPinnedVisible, setIsPinnedVisible] = useState(true);
    const [isGroupVisible, setIsGroupVisible] = useState(true);
    const [isChatsVisible, setIsChatsVisible] = useState(true);

    const togglePinnedVisibility = () => {
        setIsPinnedVisible((prev) => !prev);
    };

    const toggleGroupVisibility = () => {
        setIsGroupVisible((prev) => !prev);
    };
    const toggleChatsVisibility = () => {
        setIsChatsVisible((prev) => !prev);
    };

    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const [selectedItems, setSelectedItems] = useState([false, false, false]);

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    console.log(conversationsView?.groupConversations)

    let groupkeys;
    if (conversationsView?.groupConversations) {
        groupkeys = Object.keys(conversationsView?.groupConversations);
    }

    const [keyword, setKeyword] = useState('');
    const [currentSearchTab, setcurrentSearchTab] = useState(0);
    const [loading, setloading] = useState(false);
    const [searchedUsers, setsearchedUsers] = useState<any>([]);

    const usersService = new UsersService();

    const debouncedSearch = useCallback(
        debounce(async (keyword: string) => {
            setloading(true);
            if (keyword.trim() === '') {
                setsearchedUsers([]);
                setloading(false);
                return;
            }
            try {
                const result = await usersService.searchUsersAsync(
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                    keyword,
                );
                setloading(false);
                setsearchedUsers(result);
            } catch (error) {
                console.error('Error searching for users:', error);
            }
        }, 500),
        [instance, inProgress, currentSearchTab],
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setKeyword(newKeyword);
        debouncedSearch(newKeyword);
    };

    const groupsAlreadyRendered:any = []

    return (
        <div
            className="w-[380px] bg-[#FEFEFF] 2xl:w-[380px] xl:w-[350px] lg:w-[320px] md:w-[300px] border-r border-solid border-unicon-grayBorder"
            style={{ boxShadow: '0px 17px 40px 4px rgba(112, 144, 176, 0.06)' }}
        >
            <div className=" flex flex-col h-full justify-start ">
                <div className="flex gap-1 items-center justify-start pl-6 pr-4 h-[100px]">
                    <i className="icon icon-unicon"></i>
                    <span className="text-2xl text-unicon-lightBlue font-medium">
                        Chat<span className="font-bold">Bolt</span>
                    </span>
                </div>
                <div className="h-10 px-5 ">
                    <div
                        className=" h-10 flex items-center justify-start relative border border-[#D0D5DD] border-solid rounded-lg pl-[10px] pr-[14px]"
                        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                    >
                        <i className="icon icon-input-search cursor-pointer "></i>
                        <input
                            className="w-full h-[32px]  focus-visible:outline-none pl-[6px] text-sm text-unicon-lightBlue font-medium"
                            type="text"
                            placeholder="Search.."
                            id="search"
                            value={filterText}
                            onChange={(e) => {
                                setFilterText(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div aria-label={'chat list'} className="flex flex-col grow my-2  pt-8">
                    {conversationsView.pinnedCoversations && (
                        <div className="pb-2  ">
                            {Object.keys(conversationsView.pinnedCoversations).length > 0 && (
                                <div className="flex items-center justify-between pl-5 pr-2.5">
                                    <h3
                                        className="text-unicon-lightBlue font-medium text-base flex items-center justify-start gap-2.5 uppercase cursor-pointer"
                                        onClick={togglePinnedVisibility}
                                    >
                                        <button
                                            className={`w-[22px] h-[20px] flex items-center justify-center ${isPinnedVisible ? '' : 'icon-toggle-chat-list-collapsed'}`}
                                        >
                                            <i className="icon icon-toggle-chat-list"></i>
                                        </button>
                                        PINNED
                                    </h3>
                                    {/* <span className='bg-unicon-chatLiHover h-8 min-w-[32px] rounded-md text-base text-unicon-blue font-bold flex items-center justify-center invisible'>3</span> */}
                                </div>
                            )}
                            {isPinnedVisible && (
                                <div className="sub-menu">
                                    <ChatListSection conversations={conversationsView.pinnedCoversations} />
                                </div>
                            )}
                        </div>
                    )}
                    {conversationsView.groupConversations && (
                        <div className="pb-2">
                            <hr className="hr-line-only-chats" />
                            <div className="flex items-center justify-between pl-5 pr-2.5 pt-5 pb-1 sticky top-0 z-20">
                                <h3
                                    className="text-unicon-lightBlue font-medium text-base flex items-center justify-start gap-2.5 uppercase"
                                    onClick={toggleGroupVisibility}
                                >
                                    <button
                                        className={`w-[22px] h-[20px] flex items-center justify-center ${isGroupVisible ? '' : 'icon-toggle-chat-list-collapsed'}`}
                                    >
                                        <i className="icon icon-toggle-chat-list"></i>
                                    </button>
                                    GROUPS
                                </h3>
                                <span className="bg-unicon-chatLiHover h-8 min-w-[32px] rounded-md text-base text-unicon-blue font-bold flex items-center justify-center cursor-pointer">
                                    <i
                                        className="icon icon-add-new-chat"
                                        onClick={() => {
                                            setisCreateGroupModalOpen(true);
                                        }}
                                    ></i>
                                </span>
                            </div>
                            {isGroupVisible && (
                                <div className="sub-menu">
                                    <GroupChatListSection conversations={conversationsView.groupConversations} />
                                </div>
                            )}
                        </div>
                    )}
                    <div className="flex items-center justify-between pl-5 pr-2.5 pt-5 pb-1 sticky top-0 z-20">
                        <h3
                            className="text-unicon-lightBlue font-medium text-base flex items-center justify-start gap-2.5 uppercase"
                            onClick={toggleChatsVisibility}
                        >
                            <button
                                className={`w-[22px] h-[20px] flex items-center justify-center ${isChatsVisible ? '' : 'icon-toggle-chat-list-collapsed'}`}
                            >
                                <i className="icon icon-toggle-chat-list"></i>
                            </button>
                            CHATS
                        </h3>
                        <span className="bg-unicon-chatLiHover h-8 min-w-[32px] rounded-md text-base text-unicon-blue font-bold flex items-center justify-center cursor-pointer">
                            <i className="icon icon-add-new-chat" onClick={onAddChat}></i>
                        </span>
                    </div>
                    {isChatsVisible && (
                        <>
                            {conversationsView.chatConversations && (
                                <div>
                                    <ChatListSection conversations={conversationsView.chatConversations} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <Modal isOpen={isCreateGroupModalOpen} onClose={onClose} title="Add New Topic" subtitle="" isPrivate={true}>
                <div className="modalbody w-full relative  rounded-b-xl h-[calc(100vh-160px)] bg-unicon-activityhover">
                    <div className="modal-content-wrap  bg-unicon-activityhover  rounded-b-xl">
                        <div className="form-group-row  flex flex-col gap-5 py-[25px] px-[30px] bg-unicon-white">
                            <div className="flex items-center justify-between w-full gap-5">
                                <div className="form-group-col w-2/4">
                                    <label className="block font-semibold text-base text-unicon-lightBlue mb-[6px]">
                                        Topic Name
                                    </label>
                                    <input
                                        className="w-full  font-normal text-base text-unicon-lightBlue border border-unicon-modalBorder h-11 rounded-lg flex items-center justify-start px-[14px] bg-unicon-white focus-visible:outline-none"
                                        style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
                                        type="text"
                                        placeholder="Enter Topic Name"
                                        value={topicValue}
                                        onChange={(e) => {
                                            settopicValue(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className="form-group-col relative w-2/4">
                                    <label className="block font-semibold text-base text-unicon-lightBlue mb-[6px]">
                                        Group Name
                                    </label>
                                    <div
                                        className="typeHeadWrapPool relative border border-solid border-unicon-modalBorder rounded-lg h-11 w-full bg-white  px-[14px] flex items-center justify-start"
                                        style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
                                        onClick={() => {
                                            setIsFocused(true);
                                        }}
                                    >
                                        <input
                                            className="group-name-input w-full  font-medium text-base text-unicon-lightBlue pl-[30px] focus-visible:outline-none"
                                            type="text"
                                            placeholder="Enter Group Name"
                                            value={groupValue}
                                            onChange={(e) => {
                                                setgroupValue(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {isFocused && groupValue == '' && (
                                        <div
                                            className="filter-typeHeadWrap rounded-lg pt-[6px] pb-[12px] mt-[2px] border border-solid border-[#F2F4F7] bg-white absolute top-[75px] 
                        left-0 w-full z-20"
                                            style={{
                                                boxShadow:
                                                    '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
                                            }}
                                        >
                                            <span className="font-normal text-sm text-unicon-gray pl-[18px]">
                                                Suggestions
                                            </span>
                                            <ul className="gap-3 flex items-center justify-start px-[20px] py-[5px] flex-wrap">
                                                {groupkeys?.map((id, index) => {
                                                    const groupName:any= conversations[id].groupName;
                                                    if(groupsAlreadyRendered.includes(groupName)) return 
                                                    groupsAlreadyRendered.push(groupName)
                                                    return (
                                                        <li
                                                            className={`flex cursor-pointer items-center justify-center h-7 rounded-2xl border border-solid border-unicon-modalBorder text-Hsm font-medium text-unicon-lightBlue py-1 px-4 ${groupName == groupValue && 'bg-blue-400 text-white'}`}
                                                            style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                                            key={index}
                                                            onMouseDown={(e) => {
                                                                e.preventDefault();
                                                                setgroupValue(groupName);
                                                            }}
                                                        >
                                                            {groupName}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {openIndex == null && (
                                <div className="form-group-col ">
                                    <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                        Description
                                    </label>
                                    <textarea
                                        className="min-w-[451px]  w-[100%] h-[111px] font-normal text-base text-unicon-lightBlue border border-unicon-modalBorder rounded-lg flex items-center justify-start px-[14px] py-[10px] bg-unicon-white focus-visible:outline-none"
                                        style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
                                        name="last-name"
                                        placeholder="Enter Group Description"
                                        value={groupDescription}
                                        onChange={(e) => {
                                            setgroupDescription(e.target.value);
                                        }}
                                    ></textarea>
                                </div>
                            )}
                        </div>

                        {/* acoordion */}
                        <div className="relative w-full  py-[25px] px-[30px] border-t border-solid border-unicon-modalBorder h-auto rounded-b-xl">
                            <button
                                className="membership-expand w-6 h-6 rounded-full bg-unicon-white border border-solid border-unicon-modalBorder"
                                style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                            >
                                {openIndex != null ? (
                                    <i
                                        className="icon icon-pool-membership"
                                        onClick={() => {
                                            setOpenIndex(null);
                                        }}
                                    ></i>
                                ) : (
                                    <i
                                        className="icon icon-pool-membership"
                                        onClick={() => {
                                            setOpenIndex(0);
                                        }}
                                    ></i>
                                )}
                            </button>
                            <h3 className="font-medium text-xl text-unicon-lightBlue pb-4">Topic Membership</h3>
                            <div className="scroll-div min-h-[244px] h-full overflow-hidden overflow-y-auto rounded-b-xl">
                                <div
                                    className="accordion-list border border-solid border-unicon-modalBorder rounded  mb-5"
                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                >
                                    <div
                                        className={`py-[10px] pl-5 pr-4 flex justify-between items-center cursor-pointer border-b border-solid border-unicon-modalBorder bg-unicon-white rounded-t ${openIndex === 0 ? 'accordion-expand' : ''}`}
                                        onClick={() => {
                                            toggleAccordion(0);
                                        }}
                                    >
                                        <h4 className="font-medium text-base text-unicon-lightBlue inline-flex items-center justify-start gap-1">
                                            {(selectedUsers.length > 0 || selectedOwners.length > 0) &&
                                            openIndex !== 0 ? (
                                                <i className="icon icon-tick-circle-green"></i>
                                            ) : (
                                                <span className="w-5 h-5 rounded-full flex items-center justify-center text-unicon-white bg-unicon-accordionNum text-[11px] font-extrabold mr-2">
                                                    1
                                                </span>
                                            )}
                                            Assign Member(s) & Owner
                                        </h4>

                                        <span className="icon icon-collapsed">{openIndex === 0}</span>
                                    </div>
                                    {openIndex === 0 && (
                                        <div className="py-5 pl-6 pr-4  bg-unicon-white rounded-b">
                                            <div className="flex  justify-between gap-4">
                                                {/* right */}
                                                <div className="w-1/2  pr-[30px] ]">
                                                    <p className="text-sm text-unicon-gray font-normal ">
                                                        Select users/ Owners to add in the new group.
                                                    </p>
                                                    <div className="border-t border-solid border-unicon-modalBorder mt-4 ">
                                                        <h4
                                                            className={`w-full font-medium text-base text-unicon-lightBlue py-4`}
                                                        >
                                                            Owners
                                                        </h4>
                                                        <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[150px] w-full flex-wrap ">
                                                            <li className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] truncate pb-[2px] px-3 bg-unicon-DisableChips">
                                                                <span className="font-medium text-base text-unicon-gray min-w-[58px] max-w-[130px]">
                                                                    {activeUserInfo?.username} (You)
                                                                </span>
                                                            </li>
                                                            {selectedOwners.map((user) => {
                                                                return (
                                                                    <li
                                                                        className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] truncate py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                                                                        key={user.id}
                                                                    >
                                                                        <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px]">
                                                                            {user.userName}
                                                                        </span>
                                                                        <i
                                                                            className="icon icon-close-pills cursor-pointer"
                                                                            onClick={() => {
                                                                                toggleUser(user);
                                                                            }}
                                                                        ></i>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className="border-t border-solid border-unicon-modalBorder mt-5 ">
                                                        <h4
                                                            className={`w-full font-medium text-base text-unicon-lightBlue py-4 ${selectedUsers.length == 0 && 'opacity-0'}`}
                                                        >
                                                            Users
                                                        </h4>
                                                        <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[150px] w-full flex-wrap ">
                                                            {selectedUsers.map((user) => {
                                                                return (
                                                                    <li
                                                                        className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] truncate py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                                                                        key={user.id}
                                                                    >
                                                                        <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px]">
                                                                            {user.userName}
                                                                        </span>
                                                                        <i
                                                                            className="icon icon-close-pills cursor-pointer"
                                                                            onClick={() => {
                                                                                toggleUser(user);
                                                                            }}
                                                                        ></i>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>

                                                {/* right */}
                                                <div className="w-1/2 pr-[5px]">
                                                    <div className="typeHeadWrapPool relative border border-solid border-unicon-modalBorder rounded-lg h-11 w-full bg-white py-[10px] px-4">
                                                        <input
                                                            type="text"
                                                            className="font-medium text-base text-unicon-lightBlue w-full pl-[30px] focus-visible:outline-none"
                                                            value={keyword}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    {keyword !== '' && (
                                                        <div
                                                            className="filter-typeHeadWrap rounded-lg pt-[5px] pb-[5px] mt-[2px] border border-solid border-[#F2F4F7]"
                                                            style={{
                                                                boxShadow:
                                                                    '0px 4px 6px -2px rgba(16, 24, 40, 0.03) 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
                                                            }}
                                                        >
                                                            <span className="font-normal text-sm text-unicon-gray pl-[14px]">
                                                                Filters
                                                            </span>
                                                            <ul className="gap-3 flex items-center justify-start px-[16px] py-[5px]">
                                                                <li
                                                                    className={`${currentSearchTab == 0 && 'active-filter-pill'} flex cursor-pointer items-center justify-center h-7 rounded-2xl border border-solid border-unicon-modalBorder text-Hsm font-medium text-unicon-lightBlue py-1 px-4`}
                                                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                                                    onClick={() => {
                                                                        setcurrentSearchTab(0);
                                                                    }}
                                                                >
                                                                    Owners
                                                                </li>

                                                                <li
                                                                    className={`${currentSearchTab == 1 && 'active-filter-pill'} flex cursor-pointer items-center justify-center h-7 rounded-2xl border border-solid border-unicon-modalBorder text-Hsm font-medium text-unicon-lightBlue py-1 px-4`}
                                                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                                                    onClick={() => {
                                                                        setcurrentSearchTab(1);
                                                                    }}
                                                                >
                                                                    Users
                                                                </li>
                                                            </ul>
                                                            {loading ? (
                                                                <LoadingSpinner width="24px" height="24px" />
                                                            ) : (
                                                                <ul className=" scroll-div w-full SelecteFilterList-wrap pt-[2px] pb-[5px] max-h-[330px] overflow-hidden overflow-y-auto">
                                                                    {searchedUsers.map((user, index) => (
                                                                        <li
                                                                            key={index}
                                                                            className="flex items-center justify-between h-15 px-5 py-[10px] w-full cursor-pointer"
                                                                            onClick={() => {
                                                                                if (
                                                                                    selectedUsers.some(
                                                                                        (existingUser) =>
                                                                                            existingUser.id === user.id,
                                                                                    ) ||
                                                                                    selectedOwners.some(
                                                                                        (existingUser) =>
                                                                                            existingUser.id === user.id,
                                                                                    ) ||
                                                                                    activeUserInfo?.id ===
                                                                                        `${user.id}.412259ed-cfdd-49be-8186-814dfe58dc54`
                                                                                )
                                                                                    return;
                                                                                toggleUser(user);
                                                                            }}
                                                                        >
                                                                            <div className="flex items-center justify-start">
                                                                                <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-unicon-gray text-white text-xl font-semibold">
                                                                                    {getInitials(user.userName)}
                                                                                </div>
                                                                                <div
                                                                                    className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px]"
                                                                                    title={user.userName}
                                                                                >
                                                                                    {user.userName}
                                                                                    <br />
                                                                                    <span
                                                                                        className="text-unicon-gray text-sm leading-none"
                                                                                        title={`${user.emailid}`}
                                                                                    >
                                                                                        {user.emailid}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <button className="w-6 h-6 flex items-center justify-center">
                                                                                <i
                                                                                    className={`icon ${
                                                                                        selectedUsers.some(
                                                                                            (existingUser) =>
                                                                                                existingUser.id ===
                                                                                                user.id,
                                                                                        ) ||
                                                                                        selectedOwners.some(
                                                                                            (existingUser) =>
                                                                                                existingUser.id ===
                                                                                                user.id,
                                                                                        ) ||
                                                                                        activeUserInfo?.id ===
                                                                                            `${user.id}.412259ed-cfdd-49be-8186-814dfe58dc54`
                                                                                            ? 'icon-tick'
                                                                                            : 'icon-plus-filter'
                                                                                    }`}
                                                                                ></i>
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="accordion-list border border-solid border-unicon-modalBorder rounded mb-5"
                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                >
                                    <div
                                        className={`py-[10px] pl-5 pr-4 flex justify-between items-center cursor-pointer border-b border-solid border-unicon-modalBorder bg-unicon-white rounded-t ${openIndex === 1 ? 'accordion-expand' : ''}`}
                                        onClick={() => {
                                            toast.error('Model Selected is Not Available');
                                        }}
                                    >
                                        <h4 className="font-medium text-base text-unicon-lightBlue inline-flex items-center justify-start gap-1">
                                            <span className="w-5 h-5 rounded-full flex items-center justify-center text-unicon-white bg-unicon-accordionNum text-[11px] font-extrabold mr-2 hidden">
                                                2
                                            </span>
                                            <span className="after-edit-section w-5 h-5 rounded-full flex items-center justify-center  mr-2">
                                                <i className="icon icon-tick-circle-green"></i>
                                            </span>
                                            <span className="w-[250px]">Assigned Models </span>
                                            <span className="w-auto font-normal text-base text-unicon-lightBlue">
                                                Gpt-35-turbo
                                            </span>
                                        </h4>
                                        <span className="icon icon-collapsed">{openIndex === 1}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-t border-solid border-unicon-modalBorder flex items-center justify-end gap-4 w-full h-20 rounded-b-xl absolute bottom-0 px-[30px] bg-unicon-white">
                        <button
                            className="btn secondary-btn"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </button>
                        <button className="btn primary-btn" onClick={createGroupChat}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ChatList;
