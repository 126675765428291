import React, { useState } from 'react';
import AdminTopHeader from './AdminTopHeader';
import Sidebarsetting from '../settingPages/SettingLayout';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import Sidebar from './Sidebar';
const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>,showAdminSidebar:boolean): React.FC<P> => {
    const ComponentWithLayout: React.FC<P> = (props: P) => {
        const [isCollapsed, setisCollapsed] = useState(false);
        const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
        const currentUserRoles = activeUserInfo?.roles?.map((role) => role.roleName) || [];

        const isAdmin = ["OVERSIGHT_MANAGER", "MODEL_MANAGER", "COST_MANAGER", "GROUP_MANAGER"]
        .some(role => currentUserRoles.includes(role));
        
        return (
            <div style={{ display: 'flex' }}>
                {showAdminSidebar ? (
                    <Sidebar isCollapsed={isCollapsed} setisCollapsed={setisCollapsed} />
                ) : (
                    <Sidebarsetting isCollapsed={isCollapsed} setisCollapsed={setisCollapsed} />
                )}
                <div className={isCollapsed ? 'collapsed-sidebar' : 'expanded-sidebar'}>
                    <AdminTopHeader />
                    <main style={{ flex: '1', padding: '0px 20px 0px 20px' }} className="bg-unicon-white">
                        <WrappedComponent {...props} />
                    </main>
                </div>
            </div>
        );
    };

    ComponentWithLayout.displayName = `withLayout('Component')`;

    return ComponentWithLayout;
};

export default withLayout;
