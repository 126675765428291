import React, { useEffect, useState } from 'react';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useSort, HeaderCellSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';
import { ChatService } from '../libs/services/ChatService';
import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../libs/auth/AuthHelper';
import LoadingSpinner, { SmallLoadingSpinner } from '../hooks/Loader';
import { ConfirmationBox } from './utils/Modal';
import { useChat } from '../libs/hooks';
import ArrowUp from '../../src/assets/shorting-arrow-up.svg';
import ArrowDown from '../assets/arrow-down.svg';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';

interface Node {
    id: string;
    name: string;
    date_on: string;
}

function Archivedtable() {
    const [ids, setIds] = useState<string[]>([]);
    const [archivedChatsList, setArchivedChatsList] = useState<any>([]);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isUnarchivePopupOpen, setIsUnarchivePopupOpen] = useState(false);
    const [loader, setloader] = useState(false);

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const userId = activeUserInfo?.id.split('.')[0] ?? '';

    const handleExpand = (item: Node) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    const chat = new ChatService();
    const { instance, inProgress } = useMsal();

    const getArchivedChats = async () => {
        setloader(true);
        chat.getArchivedChats(await AuthHelper.getSKaaSAccessToken(instance, inProgress), userId).then((response) => {
            setloader(false);
            setArchivedChatsList(response);
        });
    };

    useEffect(() => {
        getArchivedChats();
    }, []);

    const theme = useTheme({
        HeaderRow: `
            .th {
             border-bottom:1px solid #EAECF0;
             border-top:1px solid #EAECF0;
             padding:12px 24px;
             background-color: #F6F7FA;
             height:44px;
            }
          `,
        BaseCell: `
           &:first-of-type {
           border-left: 1px solid #EAECF0;
           >*{display:flex;}
           }
           &:last-of-type {
           border-right:1px solid #EAECF0;
           }
            &.icon-cell >div{
            display:inline-flex;
            align-item:center;
            justify-content:end;
           }
           border-bottom:1px solid #EAECF0;
           padding:12px 24px;
            height:60px;
          `,
    });

    const [archivedDataForTable, setArchivedDataForTable] = useState<any>();

    useEffect(() => {
        const tempData: any = { nodes: [] };
        archivedChatsList.forEach((chat) => {
            const date = new Date(chat.createdOn);
            const formattedDate = date.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
            tempData.nodes.push({
                id: chat.chatId,
                name: `copilot@${formattedDate}`,
                date_on: formattedDate,
            });
        });
        setArchivedDataForTable(tempData);
    }, [archivedChatsList]);

    const chatHook = useChat();

    const [popupForChat, setPopupForChat] = useState<any>();
    const [deleteLoaderForChat, setDeleteLoaderForChat] = useState('');
    const [unarchiveLoaderForChat, setUnarchiveLoaderForChat] = useState('');

    const onDeleteChat = async () => {
        setDeleteLoaderForChat(popupForChat);
        closePopup();
        await chatHook.deleteChat(popupForChat);
        setDeleteLoaderForChat('');
    };

    const closePopup = () => {
        setIsDeletePopupOpen(false);
        setIsUnarchivePopupOpen(false);
    };

    const sort = useSort(
        archivedDataForTable,
        {
            state: {
                sortKey: 'Date',
                reverse: true,
            },
            onChange: onSortChange,
        },
        {
            sortIcon: {
                margin: '10px',
                iconDefault: <img className="w-6 h-6" src={ArrowUp} alt="" />,
                iconUp: <img className="w-6 h-6" src={ArrowDown} alt="" />,
                iconDown: <img className="w-6 h-6" src={ArrowUp} alt="" />,
            },
            sortFns: {
                Date: (array) => array.sort((a, b) => a.date_on - b.date_on),
            },
        },
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    const onUnarchiveChat = async () => {
        setUnarchiveLoaderForChat(popupForChat);
        closePopup();
        await chatHook.archiveChat(popupForChat);
        setUnarchiveLoaderForChat('');
    };

    return (
        <div>
            {loader && <LoadingSpinner />}
            {archivedDataForTable?.nodes?.length > 0 && !loader &&(
                <Table
                    data={archivedDataForTable}
                    sort={sort}
                    theme={theme}
                    className="w-full rounded max-h-[560px] scroll-div scroll-smooth scroll-p-0"
                >
                    {(tableList: Node[]) => (
                        <>
                            <Header className="">
                                <HeaderRow className="">
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">Name</HeaderCell>
                                    <HeaderCellSort
                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                        sortKey="Date"
                                    >
                                        Date
                                    </HeaderCellSort>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm"></HeaderCell>
                                </HeaderRow>
                            </Header>
                            <Body>
                                {tableList.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <Row
                                            item={item}
                                            onClick={() => {
                                                handleExpand(item);
                                            }}
                                        >
                                            <Cell className="text-unicon-lightBlue font-medium text-base">
                                                {item.name}
                                            </Cell>
                                            <Cell className="text-unicon-lightBlue font-medium text-base ">
                                                {' '}
                                                {item.date_on.toString()}{' '}
                                            </Cell>
                                            <Cell className="text-unicon-lightBlue font-medium text-base icon-cell">
                                                {unarchiveLoaderForChat === item.id ? (
                                                    <div className="w-7 h-7 flex items-center justify-end">
                                                        <SmallLoadingSpinner />
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="w-[22px] h-[22px] flex items-center justify-center"
                                                        onClick={() => {
                                                            setIsUnarchivePopupOpen(true);
                                                            setPopupForChat(item.id);
                                                        }}
                                                    >
                                                        <i className="icon icon-unarchive-grid"></i>
                                                    </button>
                                                )}
                                                {deleteLoaderForChat === item.id ? (
                                                    <div className="w-7 h-7 flex items-center justify-end">
                                                        <SmallLoadingSpinner />
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="w-[22px] h-[22px] flex items-center justify-center ml-1"
                                                        onClick={() => {
                                                            setIsDeletePopupOpen(true);
                                                            setPopupForChat(item.id);
                                                        }}
                                                    >
                                                        <i className="icon icon-delete-grid"></i>
                                                    </button>
                                                )}
                                            </Cell>
                                        </Row>
                                    </React.Fragment>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
            )}
            {
                !loader && archivedDataForTable?.nodes?.length  == 0 && <div>No Archived Chats were Found</div>
            }
            {isDeletePopupOpen && (
                <ConfirmationBox
                    iconClass="delete-chat"
                    heading="Delete Chat"
                    text="Are you sure you want to delete this chat?  This action cannot be undone."
                    callToAction={onDeleteChat}
                    closePopup={closePopup}
                />
            )}
            {isUnarchivePopupOpen && (
                <ConfirmationBox
                    iconClass="alertArchive"
                    heading="Unarchive Chat"
                    text="Are you sure you want to Unarchive this chat ?"
                    callToAction={onUnarchiveChat}
                    closePopup={closePopup}
                />
            )}
        </div>
    );
}

export default Archivedtable;
