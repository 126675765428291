import ProfileIcon from '../../assets/setting-modal/profile-icon.svg';
import MyModelsIcon from '../../assets/setting-modal/mymodel.svg';
import MyUsesIcon from '../../assets/setting-modal/activity-icon.svg';
import ChatHistoryIcon from '../../assets/setting-modal/chat.svg';
import ActivityIcon from '../../assets/setting-modal/activity-icon.svg';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
interface Tab {
    title: string;
    icon: string;
}

function Sidebarsetting({ isCollapsed, setisCollapsed }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const tabs: Tab[] = [
        { title: 'Profile', icon: ProfileIcon },
        { title: 'My Models', icon: MyModelsIcon },
        { title: 'My Usage', icon: MyUsesIcon },
        { title: 'Chat History', icon: ChatHistoryIcon },
    ];

    return (
        <div
            className={`${!isCollapsed ? 'w-[350px] 2xl:w-[350px] xl:w-[350px] lg:w-[320px] md:w-[300px] ' : ' w-[70px] '} border border-unicon-grayBorder border-b-0 bg-white relative  transition-width  min-h-screen  `}
            style={{ transition: 'width 0.3s ease', boxShadow: '0px 17px 40px 4px #7090B00F' }}
        >
            <div
                className="flex items-center justify-start h-[76px]  pl-[15px] pr-[7px] cursor-pointer"
                onClick={() => {
                    navigate(`/`);
                }}
            >
                <span className="w-[34px] h-[34px]">
                    <i className="icon icon-unicon"></i>
                </span>
                <span
                    // className={`${isCollapsed && 'opacity-0 absolute'}  text-2xl text-unicon-lightBlue font-medium leading-7`}
                    className={`${isCollapsed && 'opacity-0 '} text-2xl text-unicon-lightBlue font-medium leading-7 w-[calc(100%-40px)] pl-3 opacity-100`}
                >
                    Chat<span className="font-bold">Bolt</span>
                </span>
            </div>
            <div
                className={`${isCollapsed && 'bg-unicon-dashboardlistHover'}  h-10  bg-unicon-dashboardlistHover flex items-center justify-start pl-[18px] pr-[7px]`}
                style={{ transition: 'background 0.3s ease' }}
            >
                <span
                    className={`${isCollapsed && 'invisible '} font-medium text-xl text-unicon-lightBlue capitalize whitespace-nowrap visible `}
                >
                    Settings
                </span>
            </div>
            {tabs.map((currentTab, index) => {
                const formattedTitle = currentTab.title.replace(/ /g, '-').toLowerCase();
                const isActive = pathname.includes(formattedTitle);

                return (
                    <div
                        className={`bg-white font-medium text-xl flex capitalize leading-5 cursor-pointer h-14 items-center justify-start pl-[17px] pr-[7px] hover:bg-unicon-chatLiHover ${
                            isActive ? 'text-[#1D24CA]' : 'text-unicon-lightBlue'
                        }`}
                        key={index}
                        onClick={() => {
                            navigate(`/${formattedTitle}`);
                        }}
                        title={isCollapsed ? '' : currentTab.title}
                    >
                        <img
                            src={!isActive ? currentTab.icon : currentTab.icon}
                            alt={`${currentTab.title} icon`}
                            className={`${!isActive ? `remove-filter-setting-icon ` : ` active-filter-setting-icon`} inline-block w-7 h-7`}
                        />

                        <h2
                            className={`${isCollapsed && ' pointer-events-none opacity-0 '} whitespace-nowrap pl-[17px] opacity-100 pointer-events-auto`}
                        >
                            {currentTab.title}
                        </h2>
                    </div>
                );
            })}
            {/* {isCollapsed ? (
                <img
                    src={RightArrowIcon}
                    alt="rightarrow"
                    className="absolute right-0 translate-x-4 top-6 cursor-pointer"
                    onClick={() => {
                        setisCollapsed(false);
                    }}
                />
            ) : (
                <img
                    src={LeftArrowIcon}
                    alt="rightarrow"
                    className="absolute right-0 translate-x-4 top-6 cursor-pointer "
                    onClick={() => {
                        setisCollapsed(true);
                    }}
                />
            )} */}
        </div>
    );
}

export default Sidebarsetting;
