import React, { useEffect, useState } from 'react';
import { PoolService } from '../../libs/services/PoolService';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import withLayout from '../dashboard/WithLayout';
import UserTable from './UserTable';
import LoadingSpinner from '../../hooks/Loader';
import TokenAllotmentModal from './TokenAllotmentModal.tsx';

function UserManagement() {
    const poolService = new PoolService();

    const [LoaderForPools, setLoaderForPools] = useState(true);
    const [poolsList, setPoolsList] = useState<any>([]);
    const [isModalOpen, setisModalOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedRowsIds, setexpandedRowsIds] = useState<any>([]);
    const [usersData, setusersData] = useState({nodes:[]});
    const [triggerGetPools, settriggerGetPools] = useState(false)
    const [filterItems, setFilterItems] = useState([
        { id: 1, label: 'model 3' },
        { id: 2, label: 'model 4' },
    ]);

    const { instance, inProgress } = useMsal();

    const getPoolsList = async () => {
        setLoaderForPools(true);
        poolService.getPoolsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)).then((response) => {
            setLoaderForPools(false);
            setPoolsList(response);
        });
    };

    useEffect(() => {
        getPoolsList();
        settriggerGetPools(false)
    }, [triggerGetPools]);

    const openModal = () => {
        setisModalOpen(true);
    };

    const onClose = () => {
        setisModalOpen(false);
    };

    const fuctionCollapseAll = () => {
        setexpandedRowsIds([]);
        setIsExpanded(false);
    };

    const functionExpandAll = () => {
        const tempArray: any = [];
        usersData.nodes?.map((user: any) => {
            tempArray.push(user.userId);
        });
        setexpandedRowsIds(tempArray);
        setIsExpanded(true);
    };

    const handleExpand = (item: any) => {
        if (expandedRowsIds.includes(item.userId)) {
            setexpandedRowsIds(expandedRowsIds.filter((id) => id !== item.userId));
        } else {
            setexpandedRowsIds(expandedRowsIds.concat(item.userId));
        }
    };

    useEffect(() => {
        const tempObject: any = [];
        poolsList.map((pool) => {
            const poolAndModelInfo:any= [];
            pool.poolModelEntitlements.map((entitlements) => {
                poolAndModelInfo.push({
                    poolName: pool.poolName,
                    poolId:pool.id,
                    modelName:entitlements.modelName,
                    modelId:entitlements.modelId,
                    tokenUsage:entitlements.tokenUsage,
                    addedOn:entitlements.createdOn,
                    entitledTokenYearly:entitlements.entitledTokenYearly
                });
            });
            pool.users.map((user) => {
                const isUserAlreadyInList: any = tempObject.find((tempObjUser) => {
                    return tempObjUser.userId == user.id;
                });
                if (isUserAlreadyInList) {
                    isUserAlreadyInList.poolAndModelInfo.push(...poolAndModelInfo);
                    return;
                }
                tempObject.push({
                    userId: user.id,
                    name: user.displayName,
                    email: user.emailid,
                    role: user.role,
                    addedOn: user.createdOn,
                    poolAndModelInfo: [...poolAndModelInfo],
                });
            });
        });
        setusersData({nodes:tempObject});
    }, [poolsList]);

    const ReloadPoolsData = () => {
        settriggerGetPools(true)
    }

    return (
        <div className="px-[10px]">
            <div className="flex justify-between items-center h-[60px] ">
                <div className="inputwrap h-10 flex items-center justify-start relative">
                    <i className="icon icon-input-search absolute left-0 cursor-pointer"></i>
                    <input
                        className="min-w-[332px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[26px] text-sm text-unicon-lightBlue font-medium"
                        type="text"
                        placeholder="Search users/roles.."
                    />
                    <button
                        className={`w-10 h-10 flex items-center justify-center relative  ${filterItems.length >= 1 ? 'filter-active-icon' : ''} `}
                        style={{ transition: 'all 0.3s ease' }}
                    >
                        <i className="icon icon-filter"></i>
                    </button>
                </div>
                <div className="h-10 flex items-center justify-end">
                    <button
                        className="bg-[#387ADF] w-[117px] h-[36px] text-white rounded-lg text-base font-semibold flex items-center justify-center gap-[5px] "
                        onClick={openModal}
                    >
                        Add New <i className="icon icon-addnew"></i>
                    </button>
                    <button className="w-11 h-6 flex items-center justify-end ">
                        <i className="icon icon-excel"></i>
                    </button>
                </div>
            </div>
            <div
                className="border border-solid border-unicon-grayBorder rounded h-[calc(100vh-195px)]"
                style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
            >
                <div className="flex justify-between py-3 px-6 h-[60px] items-center border-b border-solid border-unicon-grayBorder">
                    <div
                        className={`filter-list-wrpa flex justify-start py-3 pl-1 min-h-[56px] ${filterItems.length === 0 ? 'hidden' : ''}`}
                        style={{ transition: 'all 0.3s ease' }}
                    >
                        <span className="font-semibold text-base text-unicon-gray w-[65px] m-auto">Filters :</span>
                        <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[100px] flex-wrap w-[calc(100%-65px)]">
                            {filterItems.map((item) => (
                                <li
                                    key={item.id}
                                    className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                                >
                                    <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px] truncate">
                                        {item.label}
                                    </span>
                                    <i className="icon icon-close-pills cursor-pointer"></i>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex items-center justify-end min-w-[144px]">
                        {isExpanded ? (
                            <button
                                className="flex items-center justify-start w-auto h-10 pl-[10px] pr-[16px] rounded-lg border border-solid border-unicon-grayBorder gap-2"
                                title="Collapse All"
                                onClick={fuctionCollapseAll}
                            >
                                <i className="icon icon-collapsed-all"></i>
                                <span className="text-base text-unicon-lightBlue font-semibold">Collapse all</span>
                            </button>
                        ) : (
                            <button
                                className="flex items-center justify-start w-auto h-10 pl-[10px] pr-[16px] rounded-lg border border-solid border-unicon-grayBorder gap-2"
                                title="Expand All"
                                onClick={functionExpandAll}
                            >
                                <i className="icon icon-expand-all"></i>
                                <span className="text-base text-unicon-lightBlue font-semibold">Expand all</span>
                            </button>
                        )}
                    </div>
                </div>
                {!LoaderForPools && usersData?.nodes?.length > 0 && (
                    <UserTable usersData={usersData} expandedRowsIds={expandedRowsIds} handleExpand={handleExpand} />
                )}
                {LoaderForPools && <LoadingSpinner />}
                {!LoaderForPools && usersData?.nodes?.length == 0 && (
                    <div className="w-full flex justify-center items-center h-80">No Users With Pools Were Found</div>
                )}
            </div>
            <TokenAllotmentModal isOpen={isModalOpen} onClose={onClose} usersData={usersData} poolsList={poolsList} ReloadPoolsData={ReloadPoolsData}/>
        </div>
    );
}

const UserManagementWithLayout = withLayout(UserManagement,true);

export default UserManagementWithLayout;
