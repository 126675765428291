import { AppState } from '../../App';
import {  Error, Loading } from '../views';
import ChatView from '../views/ChatView';

const Chat = ({appState}) => {
    return (
        <div className="unicon_container relative ">
            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user info. Please try signing out and signing back in.'} />
            )}
            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )}
            {appState === AppState.LoadingChats && <Loading text="Loading chats..." />}
            {appState === AppState.Chat && <ChatView />}
        </div>
    );
};

export default Chat;
