import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import withLayout from '../dashboard/WithLayout';
import Microsoft from '../../assets/microsoft.svg';
import { ChatService } from '../../libs/services/ChatService';
import { useEffect, useState } from 'react';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { formattedDate } from '../utils/Helper';
interface Node {
    id: string;
    name: string;
    model: string;
    type: string;
    create_date: string;
    status: string;
    date: string;
}
interface Data {
    nodes: Node[];
}
const nodes: Node[] = [
    {
        id: '1',
        name: 'Climate change: Actions & Impact',
        model: 'Model 1',
        type: 'Chat completion',
        create_date: '23 Feb, 2024  |  10:15 AM',
        status: 'Delete',
        date: '23 Feb, 2024  |  10:15 AM ',
    },
    {
        id: '1',
        name: 'Artificial Intelligence',
        model: 'Model 2',
        type: 'Embeddings',
        create_date: '23 Feb, 2024  |  10:15 AM',
        status: 'Archive',
        date: '23 Feb, 2024  |  10:15 AM ',
    },
];
function ChatSettingHistory() {
    const data: Data = { nodes };
    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    const chatService = new ChatService();
    const { instance, inProgress } = useMsal();
    const [loader, setloader] = useState(false);
    const [chatHistory, setchatHistory] = useState<any>({nodes:[]})

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const userid:any= activeUserInfo?.id.split(".")[0];

    const getChatHistory = async () => {
        setloader(true);
        if(!userid) return 
        chatService.getChatHistory(await AuthHelper.getSKaaSAccessToken(instance, inProgress),userid).then((response) => {
            setloader(false);
            setchatHistory({nodes:response.reverse()});
        });
    };

    useEffect(() => {
        getChatHistory()
    }, []);
    
    return (
        <div className="pt-2 bg-unicon-white">
            <div className="flex justify-between items-center h-[60px] px-[4px]">
                <div className="inputwrap h-10 flex items-end justify-start relative">
                    <i className="icon icon-input-search absolute left-0 cursor-pointer bottom-1"></i>
                    <input
                        className="min-w-[332px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[26px] text-sm text-unicon-lightBlue font-medium"
                        type="text"
                        placeholder="Search chats.."
                    />
                </div>
                <div className="flex items-center justify-end">
                    <div
                        className="min-w-[170px] h-[36px] flex items-center justify-start rounded-lg border border-solid border-unicon-grayBorder gap-[6px] pl-[9px] pr-[14px]"
                        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                    >
                        <i className="icon icon-calendar cursor-pointer"></i>
                        <input
                            className="text-unicon-lightBlue text-[13px] font-medium h-8 focus-visible:outline-none"
                            type="text"
                            value="Feb 19- Feb 29, 2024"
                            readOnly
                        />
                    </div>
                </div>
            </div>
            <div
                className="h-[calc(100vh-185px)]  rounded bg-unicon-settingBgColor border border-solid  border-unicon-grayBorder"
                style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)' }}
            >
                <Table
                    data={chatHistory}
                    theme={theme}
                    className="setting-chathistory-table w-full rounded max-h-[calc(100vh-192px)] scroll-div scroll-smooth scroll-p-0"
                >
                    {(tableList) => (
                        <>
                            <Header className="">
                                <HeaderRow className="">
                                    <HeaderCell
                                        className="text-unicon-lightBlue font-medium text-base"
                                        title="Chat Name"
                                    >
                                        Chat Name
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-base" title="Model">
                                        Model
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-base" title="Type">
                                        Type
                                    </HeaderCell>
                                    <HeaderCell
                                        className="text-unicon-lightBlue font-medium text-base"
                                        title="Created On"
                                    >
                                        Created On
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-base" title="Status">
                                        Status
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-base" title="Date">
                                        Date
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-base"></HeaderCell>
                                </HeaderRow>
                            </Header>

                            <Body>
                                {tableList.map((item) => (
                                    <Row item={item} key={item.id}>
                                        <Cell
                                            className="text-unicon-lightBlue font-medium text-base"
                                            title={item.name?.toString()}
                                        >
                                            {item.title}
                                        </Cell>
                                        <Cell
                                            className="text-unicon-lightBlue font-medium text-base"
                                            title={item.modelName?.toString()}
                                        >
                                            <div className="flex items-center justify-start cursor-pointer">
                                                <img
                                                    src={Microsoft}
                                                    alt="microsoft"
                                                    className="w-[22px] h-[22px] pr-[3px]"
                                                />
                                                {item.modelName}
                                            </div>
                                        </Cell>
                                        <Cell
                                            className="text-unicon-lightBlue font-medium text-base"
                                            title={item.type?.toString()}
                                        >
                                            {item.sessionType}
                                        </Cell>
                                        <Cell
                                            className="text-unicon-lightBlue font-medium text-base"
                                            title={item.create_date?.toString()}
                                        >
                                            {formattedDate(item.createdOn)}
                                        </Cell>
                                        <Cell
                                            className="text-unicon-lightBlue font-medium text-base status-cell"
                                            title={item.isDeleted}
                                        >
                                            {item.isDeleted ? (
                                                <div className="px-2 pt-1 pb-1 w-full bg-unicon-trendDownBg rounded-2xl text-center text-unicon-trendDownColor font-medium text-sm">
                                                    Deleted
                                                </div>
                                            ) : item.isArchived ? (
                                                <div className="px-2 pt-1 pb-1 w-full bg-unicon-chatLiHover rounded-2xl text-center text-unicon-blue font-medium text-sm">
                                                    Archived
                                                </div>
                                            ) : (
                                                <div className="px-2 pt-1 pb-1 w-full bg-unicon-chatLiHover rounded-2xl text-center text-green-500 font-medium text-sm">
                                                    Active
                                                </div>
                                            )}
                                        </Cell>
                                        <Cell
                                            className="text-unicon-lightBlue font-medium text-base"
                                            title={item.updatedOn?.toString()}
                                        >
                                            {item.updatedOn}
                                        </Cell>
                                        <Cell className="text-unicon-lightBlue font-medium text-base">
                                            <div className="flex items-center justify-center gap-2 actionDivSetting invisible">
                                                <button className="flex items-center justify-center w-7 h-6">
                                                    <i className="icon icon-setting-unarchive"></i>
                                                </button>
                                                <button className="flex items-center justify-center w-7 h-6">
                                                    <i className="icon icon-setting-delete"></i>
                                                </button>
                                            </div>
                                        </Cell>
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
            </div>
        </div>
    );
}
const ChatSettingHistorywithLayout = withLayout(ChatSettingHistory,false);
export default ChatSettingHistorywithLayout;
