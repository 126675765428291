import React, { useState } from 'react';
// import withLayout from './WithLayout';
import ModelChartCard from './ModelChartCard';
import Donutchart from './DonutChart';
import RunRateGrid from './RunRateGrid';
import Chartlineimg from '../../assets/Chart-line.svg';
import ChartDownlineimg from'../../assets/down-line-chart.svg';
import ModelCardSlider from './modelCardSlider';
import dashboardwithLayout from './DashboradLayout';
const Dashboard: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState<string>('30 days');
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleOptionSelect = (option: string): void => {
        setSelectedOption(option);
        setIsOpen(false);
        return; 
      };

    const seriesDataForUsage = [
        {
            name: 'Model 1',
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 158, 160, 170], // Steady increase
        },
        {
            name: 'Model 2',
            data: [15, 60, 70, 100, 120, 150, 180, 200, 250, 260, 280, 300], // Higher values, steeper increase
        },
        {
            name: 'Model 3',
            data: [25, 50, 80, 110, 140, 170, 200, 220, 250, 280, 300, 320], // Different curve, higher overall
        },
        {
            name: 'Model 4',
            data: [30, 80, 90, 120, 180, 240, 300, 350, 400, 420, 450, 480], // Highest values, aggressive growth
        },
    ];

    const users = [
        {
            id: 1,
            avatar: 'https://via.placeholder.com/150', // Placeholder image URL
            name: 'Albert Flore',
            email: 'albertflore@mail.com',
            amount: 6433,
        },
        {
            id: 2,
            avatar: 'https://via.placeholder.com/150',
            name: 'Jane Doe',
            email: 'janedoe@mail.com',
            amount: 7200,
        },
        {
            id: 3,
            avatar: 'https://via.placeholder.com/150',
            name: 'John Smith',
            email: 'johnsmith@mail.com',
            amount: 5400,
        },
        {
            id: 4,
            avatar: 'https://via.placeholder.com/150',
            name: 'Emma Johnson',
            email: 'emmajohnson@mail.com',
            amount: 8500,
        },
    ];
    const [isFiltersVisible, setFiltersVisible] = useState(false);

    const toggleFiltersVisibility = () => {
        setFiltersVisible((prev) => !prev);
    };

    return (
        <div>
            <div className='flex items-center justify-end h-16 px-4'>
                <div className='flex items-center justify-end'>
                { isFiltersVisible && (
                    <><div className='w-auto h-10 flex items-center justify-start bg-white border border-solid border-unicon-grayBorder rounded-lg mr-[30px]' style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}>
                            <div className='flex items-center justify-start px-3 gap-2'>
                                <span className='text-base font-semibold text-unicon-gray'>Models  :</span>
                                <span className='flex items-center justify-between bg-unicon-chatLiHover text-base text-unicon-blue font-medium rounded-2xl gap-2 pl-3 pr-2 h-6 '>model 1
                                    <i className='icon icon-close-pills cursor-pointer'></i>
                                </span>
                                <span className='flex items-center justify-between bg-unicon-chatLiHover text-base text-unicon-blue font-medium rounded-2xl gap-2 pl-3 pr-2 h-6 '>model 2
                                    <i className='icon icon-close-pills cursor-pointer'></i>
                                </span>
                            </div>

                            <div className='flex items-center justify-start pl-3 pr-3 gap-2'>
                                <span className='text-base font-semibold text-unicon-gray'>Pools  :</span>
                                <span className='flex items-center justify-between bg-unicon-chatLiHover text-base text-unicon-blue font-medium rounded-2xl gap-2 pl-3 pr-2 h-6 '>model 1
                                    <i className='icon icon-close-pills cursor-pointer'></i>
                                </span>
                                <span className='flex items-center justify-between bg-unicon-chatLiHover text-base text-unicon-blue font-medium rounded-2xl gap-2 pl-3 pr-2 h-6 '>model 2
                                    <i className='icon icon-close-pills cursor-pointer'></i>
                                </span>
                            </div>
                        </div><button className='w-[110px] flex items-center justify-between h-10 gap-x-2.5 border border-solid border-unicon-grayBorder rounded-lg bg-white mr-[15px] pl-[12px] pr-[8px]'
                            style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}><span className='text-Hsm font-medium text-unicon-lightBlue'>Clear All </span><i className='icon icon-close'></i>
                            </button></>
                )}
                </div>

                <button className={`flex items-center justify-center w-[91px] h-10 gap-x-2.5 border border-solid border-unicon-grayBorder rounded-lg bg-white mr-[15px] pl-[9px] pr-[14px] ${
          isFiltersVisible ? "filter-active" : "filter-notactive" }`} style={{ boxShadow: "0px 1px 2px 0px #1018280D", transition: 'all 0.3s ease'}} onClick={toggleFiltersVisibility}>
                    <i className='icon icon-filter-modles'></i><span className='text-Hsm font-medium text-unicon-lightBlue'>Filters</span>
                    </button>

                    <button className='flex items-center justify-center min-w-[142px] h-10 gap-x-2.5 border border-solid border-unicon-grayBorder rounded-lg bg-white mr-[15px] pl-[12px] pr-[6px]' 
                style={{boxShadow: '0px 1px 2px 0px #1018280D'}}>
                     <i className='icon icon-calender-dashboard'></i>
                     <span className='text-Hsm font-medium text-unicon-lightBlue w-[77px] overflow-hidden text-ellipsis whitespace-nowrap block'>Current Year</span>
                     <i className='icon icon-arrowdown'></i>
                    </button>

                <button className='flex items-center justify-center h-6 w-11'><i className='icon icon-excel icon-excel-dashboard'></i></button>
            </div>
            <div className='h-[calc(100vh-165px)] overflow-auto scroll-div scroll-smooth scroll-p-0'>
            <div className='h-[150h] w-full  pb-2'>
                <ModelCardSlider/>
                </div>
            <div className="grid grid-cols-4 gap-4 mt-2 px-[10px]">
                <div className="bg-white h-[360px]  col-span-3 rounded-lg border border-solid border-unicon-grayBorder pl-3 pr-2 pt-1" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    {' '}
                    <ModelChartCard modelName="Model Usage" series={seriesDataForUsage} height={300} />
                </div>
                <div className="bg-white h-[360px] rounded-lg border border-solid border-unicon-grayBorder" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    <Donutchart />
                </div>

                <div className="bg-white col-span-3 px-5 pt-1 pb-5 rounded-lg border border-solid border-unicon-grayBorder" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    <RunRateGrid />
                </div>
                <div className="bg-white  p-4 rounded-lg border border-solid border-unicon-grayBorder" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    <div className="w-full flex justify-between items-center">
                        <h3 className="flex items-center justify-start font-semibold text-unicon-lightBlue text-xl">Top Users<i className='icon icon-modelinfo ml-1' ></i></h3>
                        <h4 className="font-medium text-unicon-blue text-base">View all</h4>
                    </div>
                <div className='h-[calc(100vh-576px)] scroll-div scroll-smooth scroll-p-0 pt-2 mt-6'>
                    {users.map((user) => (
                        <div key={user.id} className="flex gap-6 items-center  border-b border-solid border-unicon-grayBorder pb-4 pt-3 justify-between ">
                            <div className="flex items-center justify-start gap-3">
                                <img src={user.avatar} alt={user.name} className="w-9 h-9 rounded-full" />
                                <div>
                                    <h3 className="font-medium text-unicon-lightBlue text-base">{user.name}</h3>
                                    <h3 className="font-normal text-base text-unicon-gray">{user.email}</h3>
                                </div>
                            </div>

                            <div className="font-semibold text-xl text-unicon-lightBlue">$ {user.amount.toLocaleString()}</div>
                        </div>
                    ))}
                </div>    
                </div>
            </div>
            </div>
           
        </div>
    );
};

const DashboardWithLayout = dashboardwithLayout(Dashboard);

export default DashboardWithLayout;
