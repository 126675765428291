import React, { useState } from 'react';
import Close from '../../assets/modal_cross.svg';
import Microsoft from '../../assets/microsoft.svg'

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    subtitle: string;
    children: React.ReactNode;
    showIcon?: boolean;
    height?:string;
    width?:string;
    renameValue?:string;
    updateRenameInputValue?:any;
    isPrivate?:boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, subtitle, children, showIcon,isPrivate }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50" onClick={onClose} />
            <div className="bg-white rounded-xl model-Modal-responsive w-[calc(100%-5rem)] 2xl:w-[calc(100%-52rem)] xl:w-[calc(100%-20rem)]  lg:w-[calc(100%-10rem)] z-10" style={{boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)'}}>
                <div className="flex w-full justify-between items-center rounded-t-xl border-b border-solid border-unicon-modalBorder h-[60px] px-[30px]">
                    <h2 className="flex items-center justify-start">
                    {showIcon && (
                            <span className='flex items-center justify-start w-[46px] h-[42px]'><img src={Microsoft} alt="model-icon" className='w-[42px] h-[42px]'/></span>
                        )}
                        <span className='font-semibold  text-xl text-unicon-lightBlue'>{title} <br/><span className='text-unicon-gray font-normal text-sm leading-[2px]'>{subtitle}</span></span>
                        {isPrivate &&  <div className='group-dropdown bg-unicon-chatLiHover rounded h-5 ml-3 pl-[8px] pr-[4px] flex items-center justify-center text-sm text-unicon-blue font-medium w-auto'>
                Private <i className='icon icon-dropdown-icon ml-[6px]'></i>
                </div>}
                        </h2>
                        
                    <img src={Close} alt="" onClick={onClose} className="cursor-pointer" />
                </div>
                <div className='w-full'>{children}</div>
            </div>
        </div>
    );
};
 
export const ConfirmationBox = ({iconClass, heading, text,callToAction,closePopup, renameInputValue,updateRenameInputValue}:any) =>   {
    return <div className="fixed inset-0 flex items-center justify-center z-50" 
    style={{position: 'fixed', width: '100%', height: '100%', overflowX: 'hidden', overflowY: 'auto', outline: '0', backgroundColor:'rgba(38, 33, 58, 0.52)', backdropFilter: 'blur(2px)'}}>   
        <div className="bg-white rounded-xl w-[510px]  z-10" style={{boxShadow: '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814'}}>
         <div className='flex items-center justify-between w-full border-b border-solid border-unicon-modalBorder py-3 pl-[16px] pr-[18px]'>
            <div className='flex items-center justify-start'><i className={`icon ${iconClass}`}></i><span className='font-semibold text-xl text-unicon-lightBlue pl-[2px]'>{heading}</span></div>
            </div> 
            <div className='px-[25px] w-full h-[150px]'>
         <div className='pt-2 font-medium text-lg text-unicon-lightBlue  leading-6'>{text}</div>
         {!!updateRenameInputValue && <input className="w-full border  border-unicon-inputBorder mb-4 rounded-lg p-2 mt-4 font-medium text-lg text-unicon-lightBlue  leading-6 focus-visible:outline-none" value={renameInputValue} onChange={updateRenameInputValue} placeholder='Enter New Chat Title'/>}

         </div>    
            <div className='alert-model-footer flex items-center justify-end h-[60px] border-t  border-solid border-unicon-modalBorder rounded-b-xl px-[25px] gap-4'>
            <button className="btn primary-btn" onClick={callToAction}>Ok</button>
            <button className="btn secondary-btn" onClick={closePopup}>Cancel</button>
            </div>
        </div>
        </div>   
}
export default Modal;
