import React, { useCallback, useEffect, useState } from 'react';
import UserManageModal from '../utils/userManageModal';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import debounce from 'lodash.debounce';
import { UsersService } from '../../libs/services/UsersService';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import { getInitials } from '../utils/Helper';
import LoadingSpinner from '../../hooks/Loader';
import Microsoft from '../../assets/microsoft.svg';
import ProgressBar from '../utils/ProgressBar';
import Close from '../../assets/modal_cross.svg';
import { toast } from 'react-toastify';

function TokenAllotmentModal({ isOpen, onClose, usersData, poolsList,ReloadPoolsData }) {
    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    const usersService = new UsersService();

    const { instance, inProgress } = useMsal();

    const [userSearchKeyword, setuserSearchKeyword] = useState<string>('');
    const [userSearchLoading, setuserSearchLoading] = useState(false);
    const [searchedUsers, setsearchedUsers] = useState<any>([]);
    const [selectedUser, setselectedUser] = useState<any>({});
    const [isPoolsDropdownOpen, setisPoolsDropdownOpen] = useState(false);
    const [selectedPools, setselectedPools] = useState<any>([]);
    const [selectedUsersPoolInfo, setselectedUsersPoolInfo] = useState<any>({ nodes: [] });

    const debouncedSearch = useCallback(
        debounce(async (keyword: string) => {
            setuserSearchLoading(true);
            if (keyword.trim() === '') {
                setsearchedUsers([]);
                setuserSearchLoading(false);
                return;
            }
            try {
                const result = await usersService.searchUsersAsync(
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                    keyword,
                );
                setuserSearchLoading(false);
                setsearchedUsers(result);
            } catch (error) {
                console.error('Error searching for users:', error);
            }
        }, 500),
        [instance, inProgress],
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setselectedUser({});
        setselectedPools([]);
        const newKeyword = event.target.value;
        setuserSearchKeyword(newKeyword);
        debouncedSearch(newKeyword);
    };

    const handleSelectUser = (user) => {
        setselectedUser(user);
        setsearchedUsers([]);
        setuserSearchKeyword('');
    };

    const handleSelectPool = (pool) => {
        setselectedPools([
            ...selectedPools,
            {
                id: pool.id,
                poolName: pool.poolName,
            },
        ]);
    };

    const handleRemoveFromSelectedPools = (pool) => {
        const tempObj = selectedPools.filter((selectedPool) => {
            return selectedPool.id != pool.id;
        });
        setselectedPools(tempObj);
    };

    const toggleSelectedPools = (pool) => {
        const tempObj = [...selectedPools];
        const PoolAlreadyExist = tempObj.find((selectedPool) => selectedPool.id === pool.id);
        if (PoolAlreadyExist) handleRemoveFromSelectedPools(pool);
        if (!PoolAlreadyExist) handleSelectPool(pool);
    };

    const togglePoolsDropdown = () => {
        setisPoolsDropdownOpen((drpdown) => !drpdown);
    };

    const getCheckedValue = (pool) => {
        const tempObj = [...selectedPools];
        const PoolAlreadyExist = tempObj.find((selectedPool) => selectedPool.id === pool.id);
        return !!PoolAlreadyExist;
    };

    useEffect(() => {
        let data;
        if (selectedUser) {
            usersData.nodes?.map((user) => {
                if (user.userId == selectedUser.id) {
                    data = user.poolAndModelInfo;
                }
            });
        }
        setselectedUsersPoolInfo({ nodes: data });
    }, [selectedUser]);

    useEffect(() => {
        const tempObj = [...selectedPools];
        selectedUsersPoolInfo.nodes?.map((usersPool) => {
            const PoolAlreadyExist = tempObj.find((selectedPool) => selectedPool.id === usersPool.poolId);
            if (PoolAlreadyExist) return;
            tempObj.push({
                id: usersPool.poolId,
                poolName: usersPool.poolName,
            });
        });
        setselectedPools(tempObj);
    }, [selectedUsersPoolInfo]);

    const body = {
        id: selectedUser.id,
        userName: selectedUser.userName,
        emailid: selectedUser.emailid,
        upn: selectedUser.upn,
        displayName: selectedUser.displayName,
        role: selectedUser.role,
        externalId: selectedUser.upn,
        pools: selectedPools,
        status: true,
    };

    const UpdateUsersPoolEntitlemnets = async () => {
        toast.success('Updating Users Pools Entitlements');
        onClose();
        try {
            await usersService
                .addUserToPoolAsync(body, await AuthHelper.getSKaaSAccessToken(instance, inProgress))
                .then((result: any) => {
                    ReloadPoolsData()
                    toast.success('Users Pool Updated');
                    setselectedPools([])
                    setselectedUser({})
                });
        } catch (e: any) {
            const errorMessage = `Unable to Update pool Entitlements`;
            toast.error(errorMessage);
        }
    };

    const callOnClose = () => {
        onClose()
        setselectedPools([])
        setselectedUser({})
        
    }

    return (
        <UserManageModal isOpen={isOpen} onClose={onClose} showIcon={false} title="Add User" subtitle="">
            <div className="modalbody w-full relative min-h-[calc(100vh-24rem)] max-h-[calc(100vh-19.3rem)] rounded-xl">
                <div className="modal-content-wrap min-h-[calc(100vh-26rem)] max-h-[calc(100vh-21.3rem)] ">
                    <div className="form-group-row  flex  justify-between gap-7 py-[25px] px-[30px] bg-unicon-white">
                        <div className="form-group-col w-full relative">
                            <label className="block font-semibold text-base text-unicon-lightBlue mb-[6px]">
                                User Name
                            </label>
                            <div
                                className="user-modal-pool-name w-full border border-unicon-inputBorder  h-11 flex items-center justify-start rounded-lg  relative"
                                style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                            >
                                <input
                                    className="w-[calc(100%-32px)] h-8 font-normal text-base text-unicon-lightBlue  flex items-center  pl-[14px] pr-[10px] bg-unicon-white  focus-visible:outline-none"
                                    type="text"
                                    placeholder="Enter User Name"
                                    value={selectedUser.displayName || userSearchKeyword}
                                    onChange={handleInputChange}
                                />
                            </div>
                            {userSearchKeyword && (
                                <div
                                    className="filter-typeHeadWrap rounded-lg mt-[2px] border border-solid border-unicon-modalBorder absolute top-[72px] z-[2] w-full bg-unicon-white "
                                    style={{ boxShadow: '0px 12px 16px -4px #10182814,0px 4px 6px -2px #10182808' }}
                                >
                                    {userSearchLoading && (
                                        <div className="h-20">
                                            <LoadingSpinner width="24px" height="24px" />
                                        </div>
                                    )}
                                    {!userSearchLoading && (
                                        <ul className=" scroll-div w-full  pt-[2px] pb-[5px] max-h-[300px] overflow-hidden overflow-y-scroll">
                                            {searchedUsers?.map((user, index) => (
                                                <li
                                                    key={index}
                                                    className="flex items-center justify-between h-15 px-[18px] py-[10px] w-full hover:bg-unicon-dashboardlistHover cursor-pointer"
                                                    onClick={() => {
                                                        handleSelectUser(user);
                                                    }}
                                                >
                                                    <div className="flex items-center justify-start">
                                                        <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-unicon-gray text-white text-xl font-semibold">
                                                            {getInitials(user.userName)}
                                                        </div>
                                                        <div
                                                            className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px]"
                                                            title={user.userName}
                                                        >
                                                            {user.userName}
                                                            <br />
                                                            <span
                                                                className="text-unicon-gray text-sm leading-none"
                                                                title={`${user.emailid.toLowerCase()}`}
                                                            >
                                                                {`${user.emailid.toLowerCase()}`}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span
                                                        className="max-w-[140px] rounded-2xl bg-unicon-chatLiHover flow-root py-[2px] px-2 text-sm font-medium text-unicon-blue overflow-hidden text-ellipsis  whitespace-nowrap line-clamp-1"
                                                        title={user.role}
                                                    >
                                                        {user.role}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="form-group-col w-full relative">
                            <label className="block font-semibold text-base text-unicon-lightBlue mb-[6px]">
                                Pool Selection<span className="text-[#C32B0A] text-base font-semibold">*</span>
                            </label>
                            <div
                                className="px-[14px] cursor-pointer max-w-full p-2 overflow-y-scroll flex items-center justify-between bg-unicon-white dropdown-button pool-dropdown-button rounded-lg max-h-11 flex flex-wrap justify-start"
                                onClick={togglePoolsDropdown}
                            >
                                {selectedPools?.length > 0 &&
                                    selectedPools.map((pool) => {
                                        return (
                                            <div
                                                key={pool.id}
                                                className="flex items-center gap-2 text-base rounded text-white font-medium flex-wrap bg-blue-500 p-1"
                                            >
                                                <h2>{pool.poolName}</h2>
                                                <img
                                                    src={Close}
                                                    alt=""
                                                    className="w-2 h-2"
                                                    onClick={() => {
                                                        handleRemoveFromSelectedPools(pool);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                <i className="icon icon-collapsed"></i>
                            </div>
                            {isPoolsDropdownOpen && (
                                <div className="dropdown-content pool-Dropdown py-2">
                                    {poolsList.map((pool) => (
                                        <label key={pool.id} className="flex items-center space-x-2">
                                            <a>
                                                <input
                                                    className="w-[22px] h-[22px] border-[1.3px] border-solid border-unicon-CheckBox rounded-sm cursor-pointer"
                                                    type="checkbox"
                                                    checked={getCheckedValue(pool)}
                                                    onChange={() => {
                                                        toggleSelectedPools(pool);
                                                    }}
                                                />
                                                <span className="text-base text-unicon-lightBlue font-medium pl-3 flex items-center justify-start capitalize">
                                                    {pool.poolName}
                                                </span>
                                            </a>
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    {selectedUsersPoolInfo.nodes?.length > 0 && (
                        <div className=" px-[30px]">
                            <h4 className="text-xl  font-medium text-unicon-lightBlue pb-[15px]">User Pool Mapping</h4>
                            <div
                                className="UserPoolMapTable border border-solid border-unicon-grayBorder rounded "
                                style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
                            >
                                <Table
                                    data={selectedUsersPoolInfo}
                                    theme={theme}
                                    className="w-full rounded max-h-[260px] scroll-div scroll-smooth scroll-p-0"
                                >
                                    {(tableList: any[]) => (
                                        <>
                                            <Header>
                                                <HeaderRow>
                                                    <HeaderCell
                                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                                        title="Pool"
                                                    >
                                                        Pool{' '}
                                                    </HeaderCell>
                                                    <HeaderCell
                                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                                        title="Model Name"
                                                    >
                                                        Model Name
                                                    </HeaderCell>
                                                    <HeaderCell
                                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                                        title="Entitled Tokens"
                                                    >
                                                        Entitled Tokens
                                                    </HeaderCell>
                                                    <HeaderCell
                                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                                        title="Token Usage (Total)"
                                                    >
                                                        Token Usage (Total)
                                                    </HeaderCell>
                                                </HeaderRow>
                                            </Header>

                                            <Body>
                                                {tableList.map((item) => (
                                                    <React.Fragment key={item.id}>
                                                        <Row item={item}>
                                                            <Cell
                                                                className="text-unicon-lightBlue font-medium text-base"
                                                                title={item.poolName?.toString()}
                                                            >
                                                                {item.poolName}
                                                            </Cell>
                                                            <Cell
                                                                className="text-unicon-lightBlue font-medium text-base"
                                                                title={item.modelName?.toString()}
                                                            >
                                                                {' '}
                                                                <div className="flex items-center justify-start cursor-pointer">
                                                                    <img
                                                                        src={Microsoft}
                                                                        alt="microsoft"
                                                                        className="w-[22px] h-[22px] pr-[3px]"
                                                                    />
                                                                    {item.modelName}
                                                                </div>
                                                            </Cell>
                                                            <Cell className="text-unicon-lightBlue font-medium text-base">
                                                                {item.entitledTokenYearly}
                                                            </Cell>
                                                            <Cell className="text-unicon-lightBlue font-medium text-base">
                                                                <ProgressBar value={item.tokenUsage} max={100} />
                                                            </Cell>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                            </Body>
                                        </>
                                    )}
                                </Table>
                            </div>
                        </div>
                    )}
                </div>

                <div className="modal-footer  border-t border-solid border-unicon-modalBorder flex items-center justify-end gap-4 w-full h-20 rounded-b-xl absolute bottom-0 px-[30px] ">
                    <button
                        className="btn secondary-btn"
                        onClick={() => {
                            callOnClose();
                        }}
                    >
                        Cancel
                    </button>
                    <button className="btn primary-btn" onClick={UpdateUsersPoolEntitlemnets}>
                        Save
                    </button>
                </div>
            </div>
        </UserManageModal>
    );
}

export default TokenAllotmentModal;
