import React from 'react';
import { useTheme } from '@table-library/react-table-library/theme';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import ProgressBar from '../utils/ProgressBar';
import { formattedDate, getInitials } from '../utils/Helper';
import Microsoft from '../../assets/microsoft.svg';


function UserTable({ usersData, expandedRowsIds, handleExpand }) {
    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    return (
        <Table
            data={usersData}
            theme={theme}
            className="w-full rounded max-h-[calc(100vh-268px)] scroll-div scroll-smooth scroll-p-0"
        >
            {(tableList: any[]) => (
                <>
                    <Header>
                        <HeaderRow>
                            <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Name">
                                Name
                            </HeaderCell>
                            <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Role">
                                Role
                            </HeaderCell>
                            <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Added On">
                                Added On
                            </HeaderCell>
                            <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Pool Name">
                                Pool Name
                            </HeaderCell>
                            <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Model Name">
                                Model
                            </HeaderCell>
                            <HeaderCell
                                className="text-unicon-lightBlue font-medium text-Hsm"
                                title="Token Usage (Total)"
                            >
                                Token Usage (Total)
                            </HeaderCell>
                        </HeaderRow>
                    </Header>

                    <Body>
                        {tableList.map((item) => (
                            <React.Fragment key={item.userId}>
                                <Row item={item}>
                                    <Cell
                                        className="text-unicon-lightBlue font-medium text-base"
                                        title={item.name?.toString()}
                                    >
                                        <div
                                            className="flex items-center justify-start"
                                            onClick={() => {
                                                handleExpand(item);
                                            }}
                                        >
                                            {expandedRowsIds.includes(item.userId) ? (
                                                <button className="flex items-center justify-center w-[30px] h-[30px]">
                                                    <i className="icon icon-arrow-right up"></i>
                                                </button>
                                            ) : (
                                                <button className="flex items-center justify-center w-[30px] h-[30px]">
                                                    <i className="icon icon-arrow-right"></i>
                                                </button>
                                            )}
                                            <div className="flex items-center justify-start">
                                                <div className="flex items-center justify-center w-[36px] h-[36px] rounded-full bg-unicon-gray text-white text-sm font-semibold">
                                                    {getInitials(item.name)}
                                                </div>
                                                <div
                                                    className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px] max-w-[148px] overflow-hidden text-ellipsis whitespace-nowrap block
                                                                "
                                                    title={item.name?.toString()}
                                                >
                                                    {item.name}
                                                    <br />
                                                    <span
                                                        className="text-unicon-gray text-sm leading-none"
                                                        title={item.user_id?.toString()}
                                                    >
                                                        User ID:{item.email}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Cell>
                                    <Cell
                                        className="text-unicon-lightBlue font-medium text-base capitalize"
                                        title={item.role?.toString()}
                                    >
                                        {item.role}
                                    </Cell>
                                    
                                    <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                    <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                    <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                    <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                </Row>

                                {expandedRowsIds.includes(item.userId) &&
                                    item.poolAndModelInfo.map((poolInfo) => {
                                        return (
                                            <Row item={poolInfo} key={poolInfo.poolId}>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"> </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    title={item.addedOn?.toString()}
                                                >
                                                    {formattedDate(poolInfo.addedOn)}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base capitalize"
                                                    title={poolInfo.poolName?.toString()}
                                                >
                                                    {poolInfo.poolName}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base capitalize"
                                                    title={poolInfo.modelName?.toString()}
                                                >
                                                    <div className="flex items-center justify-start cursor-pointer">
                                                        <img
                                                            src={Microsoft}
                                                            alt="microsoft"
                                                            className="w-[22px] h-[22px] pr-[3px]"
                                                        />
                                                        {poolInfo.modelName}
                                                    </div>
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    <ProgressBar value={poolInfo.tokenUsage} max={100} />
                                                </Cell>
                                            </Row>
                                        );
                                    })}
                            </React.Fragment>
                        ))}
                    </Body>
                </>
            )}
        </Table>
    );
}

export default UserTable;
