import React, { useState } from 'react';
import Sidebar from './Sidebar';
import AdminTopHeader from './AdminTopHeader';

const dashboardwithLayout = <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> => {
    const ComponentDashboradLayout: React.FC<P> = (props: P) => {
        const [isCollapsed, setisCollapsed] = useState(false);


        return (
            <div style={{ display: 'flex' }}>
                {/* Pass state or toggle function to Sidebar if needed */}
                <Sidebar isCollapsed={isCollapsed} setisCollapsed={setisCollapsed}  />
                <div  className={isCollapsed ? 'collapsed-sidebar' : 'expanded-sidebar'}>
                    <AdminTopHeader />
                    <main style={{ flex: '1', padding: '0px 0px 0px 0px' }} className="bg-unicon-dashboardBgColor">
                        <WrappedComponent {...props} />
                    </main>
                </div>
            </div>
        );
    };

    ComponentDashboradLayout.displayName = `dashboradLayout('Component')`;

    return ComponentDashboradLayout;
};

export default dashboardwithLayout;
