import React from 'react';

const LoadingSpinner = ({ width = '64px', height = '64px', marginTop = '24px' }: any) => {
    return (
        <div className="flex items-center justify-center w-full h-4/5">
            {/* <div
                className="border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-blue-500"
                style={{ width: width, height: height, marginTop: marginTop }}
            ></div> */}
            <span className="loader"></span>
        </div>
    );
};

export const SmallLoadingSpinner = () => {
    return <div className="flex items-center justify-center w-full h-full">
        <span className="loader"></span>
        {/* <div className="border-2 border-t-2 border-gray-200 border-solid rounded-full animate-spin border-t-blue-500 w-4 h-4"></div> */}
    </div>;
};

export default LoadingSpinner;
