import { useMsal } from '@azure/msal-react';


const LoginPage = () => {
    const { instance } = useMsal();

    const handleLogin = async () => {
        try {
            const response = await instance.loginPopup();
            if (response) {
                instance.setActiveAccount(response.account);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="bg-unicon-dashboardBgColor h-screen w-full  pt-[145px]">
            <h2 className="flex items-center justify-center">
                <i className="icon icon-unicon"></i>
                <span className="font-medium text-2xl text-unicon-lightBlue pl-1">
                    Chat<b>Bolt</b>
                </span>
            </h2>
            <h1 className="font-semibold text-unicon-lightBlue text-[40px] leading-10 text-center pt-16">
                Welcome to ChatBolt{' '}
            </h1>
            <p className="max-w-[929px] font-medium text-xxl text-unicon-gray mx-auto pt-[35px] leading-9 text-center">
                ChatBolt is your personal AI-powered assistance, ready to navigate your day and provide valuable
                insights. Let’s get started on this exciting journey together!
            </p>
            <div className="pt-[90px] flex items-center justify-center flex-col">
                <p className="font-medium text-unicon-lightBlue text-xxl pb-5">Log in to your account</p>
                <button
                    className="h-11 w-[289px] rounded-lg m-auto bg-unicon-primaryColor  text-unicon-white font-semibold text-xl leading-6"
                    data-testid="signinButton"
                    onClick={handleLogin}
                >
                    Sign in
                </button>
            </div>
            <footer className="absolute bottom-4 w-full">
                <p className="font-medium text-unicon-gray text-base text-center">
                    © 2024 ChatBolt. All Rights Reserved.
                </p>
            </footer>
        </div>
    );
};

export default LoginPage;
