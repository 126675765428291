import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useChat } from '../../../libs/hooks';
import { ConfirmationBox } from '../../utils/Modal';
import { SmallLoadingSpinner } from '../../../hooks/Loader';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import AddUserPopup from '../../utils/popups/AddUserPopup';
import RemoveUserPopup from '../../utils/popups/RemoveUserPopup';
import { ChatService } from '../../../libs/services/ChatService';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

interface Props {
    conversations: Conversations;
}

export const GroupChatListSection: React.FC<Props> = ({ conversations }) => {
    const sortedConversations = Object.fromEntries(
        Object.entries(conversations).sort(([, a], [, b]) => a.title.localeCompare(b.title)),
    );
    const chat = useChat();
    const dispatch = useAppDispatch();
    const [optionsOpenOf, setoptionsOpenOf] = useState(-1);
    const [popupForChat, setPopupForChat] = useState<any>();
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isArchivePopupOpen, setIsArchivePopupOpen] = useState(false);
    const [isPinPopupOpen, setIsPinPopupOpen] = useState(false);
    const [isUnPinPopupOpen, setIsUnPinPopupOpen] = useState(false);
    const [isRenamePopupOpen, setIsRenamePopupOpen] = useState(false);
    const [loaderForChat, setLoaderForChat] = useState('');
    const [renameInputValue, setRenameInputValue] = useState('');
    const [isAddUserPopupOpen, setisAddUserPopupOpen] = useState(false);
    const [isRemoveUserPopupOpen, setisRemoveUserPopupOpen] = useState(false);
    const [popupOpenForGroup, setpopupOpenForGroup] = useState<any>(null);
    const [isLeaveChatPopupOpen, setisLeaveChatPopupOpen] = useState<any>(false);

    const { selectedId } = useAppSelector((state: RootState) => state.conversations);

    const onClick = (id: string) => {
        dispatch(setSelectedConversation(id));
    };

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const onClose = useCallback(() => {
        setoptionsOpenOf(-1);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const onDeleteChat = async () => {
        setLoaderForChat(popupForChat);
        closePopup();
        await chat.deleteChat(popupForChat);
        setLoaderForChat('');
    };

    const onArchiveChat = async () => {
        setLoaderForChat(popupForChat);
        closePopup();
        await chat.archiveChat(popupForChat);
        setLoaderForChat('');
    };

    const onPinChat = async () => {
        closePopup();
        setLoaderForChat(popupForChat);
        await chat.pinChat(popupForChat);
        await chat.loadChats();
        setLoaderForChat('');
    };

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { instance, inProgress } = useMsal();

    const chatService = new ChatService();
    const body = {
        chatid: popupForChat,
        participantsIds: [activeUserInfo?.id],
        isgroupowner: true,
        actiontype: 'remove',
    };

    const onLeaveChat = async () => {
        closePopup();
        toast.success('Leaving Group');
        setLoaderForChat(popupForChat);
        await chatService
            .updateGroupParticipants(body, await AuthHelper.getSKaaSAccessToken(instance, inProgress))
            .then((response) => {
                console.log(response.status);
                toast.success('Group Left');
            })
            .catch((e) => {
                toast.error('Error While Leaving Group');
            });
        await chat.loadChats();
        setLoaderForChat('');
    };

    const onRenameChat = async () => {
        closePopup();
        setLoaderForChat(popupForChat);
        await chat.renameChat(popupForChat, renameInputValue);
        setRenameInputValue('');
        await chat.loadChats();
        setLoaderForChat('');
    };

    const closePopup = () => {
        setPopupForChat('');
        setIsUnPinPopupOpen(false);
        setIsRenamePopupOpen(false);
        setIsDeletePopupOpen(false);
        setIsArchivePopupOpen(false);
        setIsPinPopupOpen(false);
        setisLeaveChatPopupOpen(false);
    };

    const openAddUserPopup = (group) => {
        setoptionsOpenOf(-1);
        setpopupOpenForGroup(group);
        setisAddUserPopupOpen(true);
    };

    const openRemoveUserPopup = (group) => {
        setoptionsOpenOf(-1);
        setpopupOpenForGroup(group);
        setisRemoveUserPopupOpen(true);
    };

    const closeAddUserPopup = () => {
        setpopupOpenForGroup(null);
        setisAddUserPopupOpen(false);
    };

    const closeRemoveUserPopup = () => {
        setpopupOpenForGroup(null);
        setisRemoveUserPopupOpen(false);
    };

    const updateRenameInputValue = (e: any) => {
        setRenameInputValue(e.target.value);
    };

    const [isGroupVisible, setIsGroupVisible] = useState(true);
    const toggleGroupVisibility = () => {
        setIsGroupVisible((prev) => !prev);
    };

    const groupedData: any = Object.entries(sortedConversations).reduce<any>((groups, [key, value]) => {
        const { groupName } = value;
        if (groupName) {
            if (!groups[groupName]) {
                groups[groupName] = [];
            }
            groups[groupName].push({ ...value });
            return groups;
        }
    }, {});

    const keys = Object.keys(groupedData);

    return keys?.length > 0 ? (
        <ul className=" pb-2">
            {keys.map((groupName, index) => {
                return (
                    <div key={index} className="pt-3">
                        {groupName && (
                            <h3
                                className="text-unicon-lightBlue font-medium text-[15px]  pl-5 pr-2 h-10 capitalize flex items-center justify-start gap-2.5"
                                onClick={toggleGroupVisibility}
                            >
                                <button
                                    className={`w-[22px] h-[20px] flex items-center justify-center ${isGroupVisible ? '' : 'icon-toggle-chat-list-collapsed'}`}
                                >
                                    <i className="icon icon-toggle-chat-list"></i>
                                </button>
                                <span className="w-7 h-7">
                                    <i className="icon icon-group-list"></i>
                                </span>
                                <span className="w-[260px] text-ellipsis whitespace-nowrap overflow-hidden 2xl:w-[260px] xl:w-[228px] lg:w-[196px]">
                                    {groupName}
                                </span>
                            </h3>
                        )}
                        {isGroupVisible &&
                            groupedData[groupName].map((conversation: any) => {
                                const id = conversation.id
                                const title = conversation.title;
                                const isSelected = conversation.id === selectedId;
                                const currentParticipantInfo = conversation.users?.filter((participant) => {
                                    return participant.id == activeUserInfo?.id;
                                });
                                const groupName = conversation.groupName;
                                const owners = conversation.owners;
                                const activeUserId = activeUserInfo?.id;
                                const isOwner = owners?.includes(activeUserId);
                                console.log(currentParticipantInfo);
                                return (
                                    <div key={id}>
                                        {isGroupVisible && (
                                            <li
                                                className={` chatlist-forSideNav relative w-full h-10 cursor-pointer  hover:befpore:content-['*'] before:absolute before:left-0 before:w-[4px] before:h-full before:bg-unicon-chatBorderColor before:opacity-0 hover:before:opacity-100 hover:bg-unicon-chatLiHover  ${isSelected ? 'bg-unicon-chatLiHover' : 'cursor-pointer'}`}
                                                style={{ transition: 'all 0.2s ease' }}
                                                onClick={() => {
                                                    onClick(id);
                                                }}
                                            >
                                                <div className="flex items-center justify-start gap-2 h-10 pl-[20px] pr-[8px]">
                                                    <span className="w-6 h-6">
                                                        {currentParticipantInfo?.length > 0 && currentParticipantInfo[0].isPinned ? (
                                                            <button
                                                                className="share-button w-6 h-6 rounded-md flex items-center justify-center icon icon-pin-filled"
                                                                onClick={() => {
                                                                    setoptionsOpenOf(-1);
                                                                    setIsUnPinPopupOpen(true);
                                                                    setPopupForChat(id);
                                                                }}
                                                            ></button>
                                                        ) : (
                                                            <button
                                                                className="share-button w-6 h-6 rounded-md flex items-center justify-center icon opacity-0 hover:opacity-100 icon-pin-chartlist"
                                                                onClick={() => {
                                                                    setoptionsOpenOf(-1);
                                                                    setIsPinPopupOpen(true);
                                                                    setPopupForChat(id);
                                                                }}
                                                            ></button>
                                                        )}
                                                    </span>
                                                    <span
                                                        className={`chatListTitle  font-medium text-base mr-[5px] w-[290px] text-ellipsis whitespace-nowrap overflow-hidden 2xl:w-[290px] xl:w-[228px] lg:w-[196px] 
                                 ${isSelected ? 'text-unicon-blue' : 'text-unicon-lightBlue'}`}
                                                    >
                                                        {title}
                                                    </span>
                                                    <span className="inline-flex items-center justify-end invisible moreiconDive">
                                                        {loaderForChat === id ? (
                                                            <div className="w-6 h-6 flex items-center justify-end">
                                                                <SmallLoadingSpinner />
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="more-button w-7 h-7 rounded-md flex items-center justify-center hover:bg-unicon-iconHover"
                                                                onClick={() => {
                                                                    setoptionsOpenOf(id);
                                                                }}
                                                            >
                                                                <i className="icon icon-more"></i>
                                                            </button>
                                                        )}
                                                    </span>
                                                </div>
                                                {optionsOpenOf == id && (
                                                    <div
                                                        ref={dropdownRef}
                                                        className="w-[187px]  bg-white rounded-lg absolute right-[-167px] top-[65%] flex flex-col z-50"
                                                        style={{ boxShadow: '14px 17px 40px 4px #7090B02E'}}
                                                    >
                                                        <ul className="py-2 px-3">
                                                            <li
                                                                className="hover:bg-blue-100  h-10 cursor-pointer flex items-center justify-start"
                                                                onClick={() => {
                                                                    if (currentParticipantInfo[0]?.isPinned) {
                                                                        setIsUnPinPopupOpen(true);
                                                                    } else {
                                                                        setIsPinPopupOpen(true);
                                                                    }
                                                                    setoptionsOpenOf(-1);
                                                                    setPopupForChat(id);
                                                                }}
                                                            >
                                                                <i className="icon icon-pin-chartlist"></i>
                                                                <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                                                    {currentParticipantInfo[0]?.isPinned
                                                                        ? 'Unpin'
                                                                        : 'Pin'}
                                                                </span>
                                                            </li>
                                                            {isOwner && (
                                                                <>
                                                                    <li
                                                                        className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer"
                                                                        onClick={() => {
                                                                            setoptionsOpenOf(-1);
                                                                            setIsRenamePopupOpen(true);
                                                                            setPopupForChat(id);
                                                                        }}
                                                                    >
                                                                        <i className="icon icon-rename-chartlist"></i>
                                                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                                                            Rename
                                                                        </span>
                                                                    </li>
                                                                    <li
                                                                        className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer"
                                                                        onClick={() => {
                                                                            setoptionsOpenOf(-1);
                                                                            setPopupForChat(id);
                                                                            setIsArchivePopupOpen(true);
                                                                        }}
                                                                    >
                                                                        <i className="icon icon-archive-chartlist"></i>
                                                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                                                            Archive
                                                                        </span>
                                                                    </li>
                                                                    <li
                                                                        className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer"
                                                                        onClick={() => {
                                                                            setoptionsOpenOf(-1);
                                                                            setPopupForChat(id);
                                                                            setIsDeletePopupOpen(true);
                                                                        }}
                                                                    >
                                                                        <i className="icon icon-delete-chartlist"></i>
                                                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                                                            Delete
                                                                        </span>
                                                                    </li>
                                                                    <li
                                                                        className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer"
                                                                        onClick={() => {
                                                                            openAddUserPopup(conversations[id]);
                                                                        }}
                                                                    >
                                                                        <i className="icon icon-add-member"></i>
                                                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                                                            Add User
                                                                        </span>
                                                                    </li>
                                                                    <li
                                                                        className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer"
                                                                        onClick={() => {
                                                                            openRemoveUserPopup(conversations[id]);
                                                                        }}
                                                                    >
                                                                        <i className="icon icon-remove-member"></i>
                                                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                                                            Remove User
                                                                        </span>
                                                                    </li>
                                                                </>
                                                            )}
                                                            <li
                                                                className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer"
                                                                onClick={() => {
                                                                    setoptionsOpenOf(-1);
                                                                    setisLeaveChatPopupOpen(true);
                                                                    setPopupForChat(id);
                                                                }}
                                                            >
                                                                <i className="icon icon-leave"></i>
                                                                <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                                                    Leave Topic
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </li>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                );
            })}

            <AddUserPopup isOpen={isAddUserPopupOpen} onClose={closeAddUserPopup} group={popupOpenForGroup} />
            <RemoveUserPopup isOpen={isRemoveUserPopupOpen} onClose={closeRemoveUserPopup} group={popupOpenForGroup} />

            {isDeletePopupOpen && (
                <ConfirmationBox
                    iconClass="delete-chat"
                    heading="Delete Chat"
                    text="Are you sure you want to delete this chat?  This action cannot be undone."
                    callToAction={onDeleteChat}
                    closePopup={closePopup}
                />
            )}
            {isArchivePopupOpen && (
                <ConfirmationBox
                    iconClass="alertArchive"
                    heading="Archive Chat"
                    text="Are you sure you want to archive this chat this action cannot be undone ?"
                    callToAction={onArchiveChat}
                    closePopup={closePopup}
                />
            )}
            {isPinPopupOpen && (
                <ConfirmationBox
                    iconClass="alertPinned"
                    heading="pin Chat"
                    text="Are you sure you want to pin this chat ?"
                    callToAction={onPinChat}
                    closePopup={closePopup}
                />
            )}
            {isUnPinPopupOpen && (
                <ConfirmationBox
                    iconClass="alertPinned"
                    heading="Unpin Chat"
                    text="Are you sure you want to Unpin this chat ?"
                    callToAction={onPinChat}
                    closePopup={closePopup}
                />
            )}
            {isRenamePopupOpen && (
                <ConfirmationBox
                    iconClass="alertRename"
                    heading="Rename Chat"
                    text="Are you sure you want to rename this chat this action cannot be undone ?"
                    callToAction={onRenameChat}
                    closePopup={closePopup}
                    updateRenameInputValue={updateRenameInputValue}
                    renameInputValue={renameInputValue}
                />
            )}
            {isLeaveChatPopupOpen && (
                <ConfirmationBox
                    iconClass="icon-leave"
                    heading="Leave Group"
                    text="Are you sure you want to Leave this Group this action cannot be undone ?"
                    callToAction={onLeaveChat}
                    closePopup={closePopup}
                />
            )}
        </ul>
    ) : null;
};
