import withLayout from '../dashboard/WithLayout';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import profileIcon from '../../assets/Avatar.svg';
const ProfilePage: React.FC = () => {
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const userId = activeUserInfo?.id.split('.')[0] ?? '';
console.log(activeUserInfo)
    return (
        <div
            className="h-[calc(100vh-155px)] rounded bg-unicon-settingBgColor border border-solid  border-unicon-grayBorder my-5 p-5"
            style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)' }}
        >
            <div className="bg-unicon-white border border-solid border-unicon-grayBorder rounded">
                <div className="flex items-center justify-start h-[81px] px-3.5 border-b border-solid border-unicon-modalBorder">
                    <span className="w-16 h-16 rounded-full">
                        <img className="w-full h-full rounded-full" src={activeUserInfo?.photo} alt="rightarrow" />
                    </span>
                    <h3 className="font-semibold text-unicon-lightBlue text-xl pl-4">
                        {
                            activeUserInfo?.username
                        } <br />
                        <span className="font-medium text-base text-unicon-gray">User ID {activeUserInfo?.id.substring(0,8)}</span>
                    </h3>
                </div>
                <form className="profile-form px-[30px] py-[25px]">
                    <div className="form-group-row  flex gap-4 mb-[14px]">
                        <div className="form-group-col">
                            <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                First Name
                            </label>
                            <input
                                className="min-w-[270px]  font-normal text-base text-unicon-lightBlue border border-unicon-inputBorder h-9 rounded flex items-center justify-start px-[14px] bg-unicon-dashboardlistHover focus-visible:outline-none 2xl:min-w-[445px] xl:min-w-[345px]"
                                type="text"
                                name="first-name"
                                placeholder="Enter First Name"
                                value={activeUserInfo?.username.split(' ')[0]}
                            ></input>
                        </div>
                        <div className="form-group-col">
                            <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                Last Name
                            </label>
                            <input
                                className="min-w-[270px]  font-normal text-base text-unicon-lightBlue border border-unicon-inputBorder h-9 rounded flex items-center justify-start px-[14px] bg-unicon-dashboardlistHover focus-visible:outline-none 2xl:min-w-[445px] xl:min-w-[345px]"
                                type="text"
                                name="last-name"
                                placeholder="Enter last Name"
                                value={activeUserInfo?.username.split(' ')[1]}
                            ></input>
                        </div>
                    </div>
                    <div className="form-group-row  flex gap-4 mb-[14px]">
                        <div className="form-group-col">
                            <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                Email ID
                            </label>
                            <div className="inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px] bg-unicon-dashboardlistHover">
                                <i className="icon email-icon"></i>{' '}
                                <input
                                    className=" min-w-[224px]  font-normal text-base text-unicon-gray   flex items-center justify-start pl-[8px]  bg-unicon-dashboardlistHover  focus-visible:outline-none 2xl:min-w-[400px] xl:min-w-[300px]"
                                    type="email"
                                    disabled
                                    name="email"
                                    placeholder="Enter Email ID"
                                    value={activeUserInfo?.email}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group-col">
                            <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                Contact No.
                            </label>
                            <div className="inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px] bg-unicon-dashboardlistHover">
                                <i className="icon contact-icon"></i>{' '}
                                <input
                                    className=" min-w-[224px]  font-normal text-base text-unicon-lightBlue   flex items-center justify-start pl-[8px]  bg-unicon-dashboardlistHover focus-visible:outline-none 2xl:min-w-[400px] xl:min-w-[300px]"
                                    type="number"
                                    disabled
                                    name="contact"
                                    placeholder="Not Available"
                                ></input>
                            </div>
                        </div>
                    </div>

                    <div className="form-group-row  flex gap-4 ">
                        <div className="form-group-col">
                            <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                Location
                            </label>
                            <div className="inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px] bg-unicon-dashboardlistHover">
                                <i className="icon location-icon"></i>{' '}
                                <input
                                    className=" min-w-[224px]  font-normal text-base text-unicon-lightBlue   flex items-center justify-start pl-[8px]  bg-unicon-dashboardlistHover focus-visible:outline-none  2xl:min-w-[400px] xl:min-w-[300px]"
                                    type="number"
                                    disabled
                                    name="contact"
                                    placeholder="Not Available"
                                ></input>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const ProfileWithLayout = withLayout(ProfilePage,false);

export default ProfileWithLayout;
